var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-mock" }, [
    _c("div", { staticClass: "form-area" }, [
      _c("div", { staticClass: "head-area" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n        物流下单信息填写\n      "),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "title-login", on: { click: _vm.goLogin } }, [
          _vm._v("\n        登陆\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-content" },
        _vm._l(_vm.formList, function (item, index) {
          return _c("div", { key: index, staticClass: "form-item" }, [
            item.mutiple === false
              ? _c("div", { staticClass: "form-label" }, [
                  item.required && !item.readonly
                    ? _c("span", { staticClass: "require-mark" }, [_vm._v("*")])
                    : _vm._e(),
                  _vm._v(" "),
                  item.readonly && !item.required
                    ? _c("span", { staticClass: "readonly" })
                    : _vm._e(),
                  _vm._v("\n          " + _vm._s(item.label) + ":\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            item.mutiple === true && item.children
              ? _c(
                  "div",
                  { staticClass: "form-label form-column" },
                  _vm._l(item.children, function (child, childIndex) {
                    return _c(
                      "div",
                      { key: childIndex, staticClass: "form-column-tw0" },
                      [
                        item.required
                          ? _c("span", { staticClass: "require-mark" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(child.label) +
                            ":\n          "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "input"
              ? _c(
                  "div",
                  { staticClass: "form-item-detail" },
                  [
                    _c("Input", {
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "select" && item.mutiple === false
              ? _c(
                  "div",
                  { staticClass: "form-item-detail" },
                  [
                    _c(
                      "Select",
                      {
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      },
                      _vm._l(item.props.options, function (inner, innerIndex) {
                        return _c(
                          "Option",
                          { key: innerIndex, attrs: { value: inner.value } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(inner.title) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "select" && item.mutiple === true
              ? _c(
                  "div",
                  { staticClass: "form-item-detail" },
                  _vm._l(item.children, function (child, childIndex) {
                    return _c(
                      "div",
                      { key: childIndex, staticClass: "form-column-detail" },
                      [
                        _c(
                          "Select",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.onChange($event, index, childIndex)
                              },
                            },
                            model: {
                              value: child.value,
                              callback: function ($$v) {
                                _vm.$set(child, "value", $$v)
                              },
                              expression: "child.value",
                            },
                          },
                          _vm._l(
                            child.props.options,
                            function (inner, innerIndex) {
                              return _c(
                                "Option",
                                {
                                  key: innerIndex,
                                  attrs: { value: inner.title },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(inner.title) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "text"
              ? _c("div", { staticClass: "form-item-detail" }, [
                  _c("span", { staticClass: "form-text" }, [
                    _vm._v(_vm._s(item.value)),
                  ]),
                  _vm._v(" "),
                  item.button
                    ? _c(
                        "span",
                        {
                          staticClass: "operate",
                          on: {
                            click: function ($event) {
                              return _vm.createBillNo(index)
                            },
                          },
                        },
                        [_vm._v("生成单号")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            item.type === "date"
              ? _c(
                  "div",
                  { staticClass: "form-item-detail" },
                  [
                    _c("DatePicker", {
                      attrs: {
                        value: item.value,
                        type: "date",
                        placeholder: "请选择",
                      },
                      on: {
                        "on-change": function ($event) {
                          return _vm.dateChange($event, index)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            item.type === "daterange"
              ? _c(
                  "div",
                  { staticClass: "form-item-detail" },
                  [
                    _c("DatePicker", {
                      attrs: {
                        value: item.value,
                        type: "daterange",
                        placeholder: "请选择",
                      },
                      on: {
                        "on-change": function ($event) {
                          return _vm.dateChange($event, index)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-area" },
        [
          _c(
            "Button",
            { attrs: { type: "error" }, on: { click: _vm.getSubmit } },
            [_vm._v("\n        提交\n      ")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "result-area" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "result-item" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "result-show" }, [
          _vm._v("\n        " + _vm._s(_vm.result) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-item" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "result-show" }, [
          _vm._v("\n        " + _vm._s(_vm.response) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _vm.barcode
        ? _c("div", { staticClass: "result-item" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "result-show" }, [
              _c("img", {
                staticClass: "barCode",
                attrs: {
                  src: `http://admin-test.wuliu.piplus.cn/wuliu-app/generate_barcode?barcode=${_vm.barcode}`,
                },
              }),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mean-area" }, [
      _c("span", { staticClass: "mean-item" }, [
        _vm._v("\n          带"),
        _c("span", { staticClass: "require-mark" }, [_vm._v("*")]),
        _vm._v("选项必须填写\n        "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mean-item" }, [
        _vm._v("\n          带"),
        _c("span", { staticClass: "readonly" }),
        _vm._v("不可编辑\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "head-area" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        下单结果\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-label" }, [
      _c("span", { staticClass: "readonly" }),
      _vm._v("\n        下单结果:\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-label" }, [
      _c("span", { staticClass: "readonly" }),
      _vm._v("\n        响应报文:\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-label" }, [
      _c("span", { staticClass: "readonly" }),
      _vm._v("\n        打印条码:\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }