// 格式化日期
function formatDate(date, symbol) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    if (symbol) {
        return y + symbol + m + symbol + d;
    } else {
        return y + '-' + m + '-' + d;
    }
}
// 获取某时间段内所有日期
function getAllDate(begin, end) {
    let arr = [];
    let ab = begin.split('-');
    let ae = end.split('-');
    let db = new Date();
    db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
    let de = new Date();
    de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
    let unixDb = db.getTime() - 24 * 60 * 60 * 1000;
    let unixDe = de.getTime() - 24 * 60 * 60 * 1000;
    for (let k = unixDb; k <= unixDe; ) {
        k = k + 24 * 60 * 60 * 1000;
        arr.push(formatDate(new Date(parseInt(k))));
    }
    return arr;
}

export { formatDate, getAllDate };
