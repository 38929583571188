var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.isMobile
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapse,
                    expression: "!collapse",
                  },
                ],
                staticClass: "logistic-content",
                style: { height: _vm.containerHeight - 40 + "px" },
              },
              [
                _c("div", { staticClass: "content-top" }, [
                  _c("div", { staticClass: "message-item" }, [
                    _c("span", [_vm._v("快递公司：")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.carrier))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "message-item" }, [
                    _c("span", [_vm._v("快递号：")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.mailNo))]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content-bottom",
                    style: { height: _vm.containerHeight - 100 + "px" },
                  },
                  [
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v(
                        "\n                    物流信息\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        style: {
                          height: _vm.containerHeight - 145 + "px",
                          overflow: "auto",
                        },
                      },
                      [
                        _c(
                          "Timeline",
                          _vm._l(_vm.info.routeList, function (item, index) {
                            return _c(
                              "TimelineItem",
                              { key: index, attrs: { color: "red" } },
                              [
                                _c("div", {
                                  staticClass: "time-mark",
                                  attrs: { slot: "dot" },
                                  slot: "dot",
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "content-detail" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.remark) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "content-detail" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.time) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "control-bar",
                class: [_vm.collapse ? "collopse" : ""],
                on: { click: _vm.controlPane },
              },
              [
                !_vm.collapse
                  ? _c("img", {
                      staticClass: "arrow",
                      attrs: {
                        src: require("../../assets/image/left-arrow.png"),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.collapse
                  ? _c("img", {
                      staticClass: "arrow",
                      attrs: {
                        src: require("../../assets/image/right-arrow.png"),
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "logistic-tail",
              attrs: { id: "container" },
            }),
          ]
        : [
            _c("div", {
              staticClass: "logistic-tail mobile",
              attrs: { id: "container" },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mobile-timeline" },
              [
                _c(
                  "Timeline",
                  _vm._l(_vm.info.routeList, function (item, index) {
                    return _c(
                      "TimelineItem",
                      { key: index, attrs: { color: "red" } },
                      [
                        _c("div", {
                          staticClass: "time-mark",
                          attrs: { slot: "dot" },
                          slot: "dot",
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "content-detail" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.remark) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "content-detail" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.time) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }