<template>
    <div class="app">
        <homePage :isWelcomePage="true" />
    </div>
</template>

<script>
import homePage from '../component/customizedPage/homePage';
export default {
    components: { homePage }
};
</script>

<style>
.app {
    height: 100%;
    width: 100%;
}
</style>
