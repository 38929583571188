/* eslint-disable prefer-const */
//物流轨迹页面
<template>
  <div class="container">
    <div
      v-show="!collapse"
      class="logistic-content"
      :style="{height:(containerHeight-40)+'px'}"
    >
      <div class="content-top">
        <div class="message-item">
          <span>快递公司：</span>
          <span>{{ info.carrier }}</span>
        </div>
        <div class="message-item">
          <span>快递号：</span>
          <span>{{ info.mailNo }}</span>
        </div>
      </div>
      <div
        class="content-bottom"
        :style="{height:(containerHeight-100)+'px'}"
      >
        <div class="content-title">
          物流信息
        </div>
        <div class="time-content">
          <Timeline>
            <TimelineItem
              v-for="(item,index) in info.routeList"
              :key="index"
              color="red"
            >
              <div
                slot="dot"
                class="time-mark"
              />
              <p class="content-detail">
                {{ item.remark }}
              </p>
              <p class="content-detail">
                {{ item.time }}
              </p>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
    </div>
    <div
      class="control-bar"
      :class="[collapse? 'collopse':'']"
      @click="controlPane"
    >
      <img
        v-if="!collapse"
        class="arrow"
        src="../../assets/image/left-arrow.png"
      ></img>
      <img
        v-if="collapse"
        class="arrow"
        src="../../assets/image/right-arrow.png"
      ></img>
    </div>
    <baidu-map
      ref="element"
      class="logistic-tail"
      ak="3ooPMfmcTkEHOhS8GpLcXizRUOCHH99x"
      :center="info.senderCityLngLat"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      :auto-resize="true"
      @ready="handler"
      @resize="resize"
    >
      <bm-view style="width: 100%; height:100%;" />
      <bm-scale
        anchor="BMAP_ANCHOR_TOP_RIGHT"
        show-zoom-info="true"
      />
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :show-address-bar="true"
        :auto-location="true"
      />
      <!-- <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" /> -->
      <bm-polyline
        :path="polylinePath"
        :stroke-color="lineColor"
        :stroke-opacity="0.9"
        :stroke-weight="4"
        :editing="true"
        :icons="icons"
        @lineupdate="updatePolylinePath"
      />
      <bm-marker
        v-if="info.senderCityLngLat&&Object.keys(info.senderCityLngLat).length>0"
        :position="{lng: info.senderCityLngLat.lng, lat: info.senderCityLngLat.lat}"
      >
        <bm-overlay
          v-if="info.senderCity"
          pane="labelPane"
          :class="{sample: true, active}"
          @draw="draws"
          @mouseover.native="active = true"
          @mouseleave.native="active = false"
        >
          <div class="label-container">
            <span class="label-tip">发</span>
            <span class="city">{{ info.senderCity }}</span>
          </div>
        </bm-overlay>
      </bm-marker>
      <bm-marker
        v-if="info.receiverCityLngLat"
        :position="{lng: info.receiverCityLngLat.lng, lat: info.receiverCityLngLat.lat}"
      >
        <bm-overlay
          v-if="info.receiverCity"
          pane="labelPane"
          :class="{sample: true, active}"
          @draw="drawR"
          @mouseover.native="active = true"
          @mouseleave.native="active = false"
        >
          <div class="label-container">
            <span class="label-tip">收</span>
            <span class="city">{{ info.receiverCity }}</span>
          </div>
        </bm-overlay>
      </bm-marker>
      <bm-marker
        v-if="info.map.length>0"
        :position="{lng: info.map[info.map.length-1].lng, lat: info.map[info.map.length-1].lat}"
      >
        <bm-overlay
          v-if="info.mark&&Object.keys(info.mark).length>0"
          pane="labelPane"
          :class="{sample: true, active}"
          @draw="drawC"
          @mouseover.native="active = true"
          @mouseleave.native="active = false"
        >
          <div class="label-container">
            <img
              class="send"
              src="../../../static/image/logistic-status.png"
            ></img>
            <div>
            <span class="city">{{ info.mark.city }}</span>
            <span class="time">{{ info.mark.time }}到达</span>
            </div>
          </div>
        </bm-overlay>
      </bm-marker>
    </baidu-map>
  </div>
</template>
<script>
  import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
  import BmView from 'vue-baidu-map/components/map/MapView.vue';
  import BmScale from 'vue-baidu-map/components/controls/Scale';
  import BmNavigation from 'vue-baidu-map/components/controls/Navigation';
  import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation';
  import BmCityList from 'vue-baidu-map/components/controls/CityList';
  import BmPolyline from 'vue-baidu-map/components/overlays/Polyline';
  import BmMarker from 'vue-baidu-map/components/overlays/Marker';
  import BmIcon from 'vue-baidu-map/components/overlays/Icon';
  import BmOverlay from 'vue-baidu-map/components/overlays/Overlay';
  import R3 from '@syman/burgeon-r3';

  const { network } = R3;
  export default {
    components: {
      BaiduMap,
      BmView,
      BmScale,
      BmGeolocation,
      BmNavigation,
      BmPolyline,
      BmCityList,
      BmMarker,
      BmIcon,
      BmOverlay
    },
    data() {
      return {
        center: { lng: 0, lat: 0 },
        zoom: 4,
        polylinePath: [
          { lng: 116.404, lat: 39.915 },
          { lng: 116.405, lat: 39.92 },
          { lng: 116.423493, lat: 39.907445 }
        ],
        icons: [],
        lineColor: '#FD6442',
        containerHeight: '',
        info: {
          mailNo: '单号',
          carrier: '快递公司',
          senderCityLngLat: '发送城市经纬',
          senderCity: '发送城市',
          receiverCity: '收货城市',
          receiverCityLngLat: '收货城市经纬',
          routeList: [
            { time: '时间', city: '城市', remark: '描述' },
            { time: '时间2', city: '城市2', remark: '描述2' }
          ],
          map: [{ lng: '123.13', lat: '113.21', city: '城市' }],
          mark: {}
        },
        active: false,
        collapse: false,
      };
    },
    methods: {
      handler({ BMap, map }) {
        // console.log(BMap, map);
        this.center.lng = 116.404;
        this.center.lat = 39.915;
        this.zoom = 6;
        // setTimeout(() => {
        this.getMapData();
      // }, 300);
      },
      resize({ type, target, size }) {
        this.center.lng = 116.404;
        this.center.lat = 39.915;
        this.zoom = 6;
      }, // 地图的视图变化时调用的函数
      draws({ el, BMap, map }) {
        const { lng, lat } = this.info.senderCityLngLat;
        const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
        el.style.left = `${pixel.x - 20}px`;
        el.style.top = `${pixel.y - 50}px`;
      },
      drawR({ el, BMap, map }) {
        if (this.info.receiverCityLngLat) {
          const { lng, lat } = this.info.receiverCityLngLat;
          const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
         
          el.style.left = `${pixel.x - 20}px`;
          el.style.top = `${pixel.y - 50}px`;
        }
      },
      drawC({ el, BMap, map }) {
        if (this.info.map[this.info.map.length - 1]) {
          const { lng, lat } = this.info.map[this.info.map.length - 1];
          const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));

          el.style.left = `${pixel.x - 20}px`;
          el.style.top = `${pixel.y - 50}px`;
        }
      },
      updatePolylinePath(e) {
        // console.log(e, '121');
        this.polylinePath = e.target.getPath();
      },
      getMapData() {
        const key = R3.getModuleName();
        network
          .post('/p/cs/wuliu/order/query_route_city', {
            id: JSON.parse(
              sessionStorage.getItem('undataFromPageCustomizeButtonInfo')
            )[key].itemId
            // id: 203
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.info = res.data.data;
              if (res.data.data.map.length > 0 && res.data.data.routeList.length > 0) {
                this.polylinePath = res.data.data.map.map(item => ({
                  lng: item.lng,
                  lat: item.lat
                }));
               
                const findIndex = res.data.data.routeList.findIndex(item => item.city === res.data.data.map[res.data.data.map.length - 1].city);
                this.info.mark = Object.assign(res.data.data.map[res.data.data.map.length - 1], res.data.data.routeList[findIndex]);
              }
            
            // console.log(this.polylinePath, '点');
            }
          });
      },
      controlPane() {
        this.collapse = !this.collapse;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.containerHeight = document.getElementsByClassName(
          'logistic-tail'
        )[0].offsetHeight;
      });
    }
  };
</script>
<style lang="less">
.container {
  width: 100%;
  height: 100%;
  display: flex;
  position:relative;
  .logistic-content {
    // left: 0;
    // top: 0;
    width: 309px;
    height: 400px;
    background: #fff;
    .content-top {
      padding: 7px 20px;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      .message-item {
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: rgba(78, 78, 78, 1);
        line-height: 20px;
      }
    }
    .content-bottom {
      box-sizing: border-box;
      padding: 10px 23px;
      overflow: auto;
      .content-title {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(78, 78, 78, 1);
        line-height: 20px;
      }
      .time-content {
        max-height: 400px;
        overflow: auto;
        .burgeon-timeline-item-head-custom {
          left: 1px;
        }
        .content-detail {
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: rgba(182, 182, 182, 1);
          line-height: 20px;
        }
        .time-mark {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: rgba(253, 100, 66, 1);
          border: 2px solid rgba(253, 100, 66, 0.2);
        }
      }
    }
  }
   .control-bar{
      // display:block;
      position:absolute;
      top:50%;
      margin-top:-40px;
      left:309px;
      width:20px;
      height:80px;
      box-sizing:border-box;
      background-image: url('../../assets/image/controlBar.png');
      background-size: contain;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow{
       
      }
    }
    .collopse{
        left:0;
    }
  .logistic-tail {
    flex: 1;
    height: 100%;
    .BMap_vectex_node {
      width: 12px;
      height: 12px;
      background: rgba(255, 255, 255, 1);
      border: 4px solid rgba(253, 100, 66, 1);
      border-radius: 50%;
    }
    .BMap_vectex_nodeT {
      background: rgba(255, 255, 255, 1);
      border: 1px solid #000;
      border-radius: 50%;
    }
    .sample {
      height: 26px;
      background: #fff;
      position: absolute;
      .label-container {
        position: absolute;
        background: #fff;
        white-space: nowrap;
         display:flex;
      .label-tip {
        display: inline-block;
        background: rgba(253, 100, 66, 1);
        border-radius: 4px 0px 0px 4px;
        width: 26px;
        height: 26px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
          line-height: 26px;
        text-align: center;
      }
       
        .send {
          display: inline-block;
          width: 42px;
          height: 42px;
        }
      .city {
          padding: 2px 10px 2px 8px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(101, 101, 101, 1);
          line-height: 26px;
          vertical-align: top;
        }
        .time{
          display:block;
          font-size:12px;
          font-family:PingFang-SC-Medium,PingFang-SC;
          font-weight:500;
          color:rgba(101,101,101,1);
          line-height:17px;
          vertical-align: bottom;
        }
      }
    }
  }
}
</style>
