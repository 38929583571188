var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loginBG" }, [
    _c("div", { ref: "container", staticClass: "container" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/image/loginBg3.png"), alt: "logo" },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "titleTOP" }, [_vm._v("用户登录")]),
      _vm._v(" "),
      _c("div", { staticClass: "divAccount" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../assets/image/account.png") },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "username",
          staticClass: "username",
          attrs: { type: "text", value: "", placeholder: "请输入用户名" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divMima" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../assets/image/password.png") },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "password",
          staticClass: "pwd",
          attrs: { type: "password", value: "", placeholder: "请输入密码" },
        }),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "btn",
        attrs: { id: "btn" },
        on: { click: _vm.login },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }