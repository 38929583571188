var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapse,
              expression: "!collapse",
            },
          ],
          staticClass: "logistic-content",
          style: { height: _vm.containerHeight - 40 + "px" },
        },
        [
          _c("div", { staticClass: "content-top" }, [
            _c("div", { staticClass: "message-item" }, [
              _c("span", [_vm._v("快递公司：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.info.carrier))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "message-item" }, [
              _c("span", [_vm._v("快递号：")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.info.mailNo))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "content-bottom",
              style: { height: _vm.containerHeight - 100 + "px" },
            },
            [
              _c("div", { staticClass: "content-title" }, [
                _vm._v("\n        物流信息\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "time-content" },
                [
                  _c(
                    "Timeline",
                    _vm._l(_vm.info.routeList, function (item, index) {
                      return _c(
                        "TimelineItem",
                        { key: index, attrs: { color: "red" } },
                        [
                          _c("div", {
                            staticClass: "time-mark",
                            attrs: { slot: "dot" },
                            slot: "dot",
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "content-detail" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.remark) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "content-detail" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.time) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "control-bar",
          class: [_vm.collapse ? "collopse" : ""],
          on: { click: _vm.controlPane },
        },
        [
          !_vm.collapse
            ? _c("img", {
                staticClass: "arrow",
                attrs: { src: require("../../assets/image/left-arrow.png") },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.collapse
            ? _c("img", {
                staticClass: "arrow",
                attrs: { src: require("../../assets/image/right-arrow.png") },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "baidu-map",
        {
          ref: "element",
          staticClass: "logistic-tail",
          attrs: {
            ak: "3ooPMfmcTkEHOhS8GpLcXizRUOCHH99x",
            center: _vm.info.senderCityLngLat,
            zoom: _vm.zoom,
            "scroll-wheel-zoom": true,
            "auto-resize": true,
          },
          on: { ready: _vm.handler, resize: _vm.resize },
        },
        [
          _c("bm-view", { staticStyle: { width: "100%", height: "100%" } }),
          _vm._v(" "),
          _c("bm-scale", {
            attrs: {
              anchor: "BMAP_ANCHOR_TOP_RIGHT",
              "show-zoom-info": "true",
            },
          }),
          _vm._v(" "),
          _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
          _vm._v(" "),
          _c("bm-geolocation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              "show-address-bar": true,
              "auto-location": true,
            },
          }),
          _vm._v(" "),
          _c("bm-polyline", {
            attrs: {
              path: _vm.polylinePath,
              "stroke-color": _vm.lineColor,
              "stroke-opacity": 0.9,
              "stroke-weight": 4,
              editing: true,
              icons: _vm.icons,
            },
            on: { lineupdate: _vm.updatePolylinePath },
          }),
          _vm._v(" "),
          _vm.info.senderCityLngLat &&
          Object.keys(_vm.info.senderCityLngLat).length > 0
            ? _c(
                "bm-marker",
                {
                  attrs: {
                    position: {
                      lng: _vm.info.senderCityLngLat.lng,
                      lat: _vm.info.senderCityLngLat.lat,
                    },
                  },
                },
                [
                  _vm.info.senderCity
                    ? _c(
                        "bm-overlay",
                        {
                          class: { sample: true, active: _vm.active },
                          attrs: { pane: "labelPane" },
                          on: { draw: _vm.draws },
                          nativeOn: {
                            mouseover: function ($event) {
                              _vm.active = true
                            },
                            mouseleave: function ($event) {
                              _vm.active = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "label-container" }, [
                            _c("span", { staticClass: "label-tip" }, [
                              _vm._v("发"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "city" }, [
                              _vm._v(_vm._s(_vm.info.senderCity)),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.info.receiverCityLngLat
            ? _c(
                "bm-marker",
                {
                  attrs: {
                    position: {
                      lng: _vm.info.receiverCityLngLat.lng,
                      lat: _vm.info.receiverCityLngLat.lat,
                    },
                  },
                },
                [
                  _vm.info.receiverCity
                    ? _c(
                        "bm-overlay",
                        {
                          class: { sample: true, active: _vm.active },
                          attrs: { pane: "labelPane" },
                          on: { draw: _vm.drawR },
                          nativeOn: {
                            mouseover: function ($event) {
                              _vm.active = true
                            },
                            mouseleave: function ($event) {
                              _vm.active = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "label-container" }, [
                            _c("span", { staticClass: "label-tip" }, [
                              _vm._v("收"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "city" }, [
                              _vm._v(_vm._s(_vm.info.receiverCity)),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.info.map.length > 0
            ? _c(
                "bm-marker",
                {
                  attrs: {
                    position: {
                      lng: _vm.info.map[_vm.info.map.length - 1].lng,
                      lat: _vm.info.map[_vm.info.map.length - 1].lat,
                    },
                  },
                },
                [
                  _vm.info.mark && Object.keys(_vm.info.mark).length > 0
                    ? _c(
                        "bm-overlay",
                        {
                          class: { sample: true, active: _vm.active },
                          attrs: { pane: "labelPane" },
                          on: { draw: _vm.drawC },
                          nativeOn: {
                            mouseover: function ($event) {
                              _vm.active = true
                            },
                            mouseleave: function ($event) {
                              _vm.active = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "label-container" }, [
                            _c("img", {
                              staticClass: "send",
                              attrs: {
                                src: require("../../../static/image/logistic-status.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", { staticClass: "city" }, [
                                _vm._v(_vm._s(_vm.info.mark.city)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "time" }, [
                                _vm._v(_vm._s(_vm.info.mark.time) + "到达"),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }