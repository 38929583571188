var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app" }, [
    _vm.isAdmin
      ? _c("div", { staticClass: "welcomepage" })
      : _c("div", { staticClass: "operationAnalysis" }, [
          _c("div", { staticClass: "layout top-layout" }, [
            _c(
              "div",
              { staticClass: "filter" },
              [
                _c("div", { staticClass: "sub-title" }, [_vm._v("运单状态")]),
                _vm._v(" "),
                _c("DatePicker", {
                  staticClass: "dateRange",
                  staticStyle: { "margin-left": "auto" },
                  attrs: {
                    type: "daterange",
                    options: _vm.dpOptions,
                    format: "yyyy/MM/dd",
                    placement: "bottom-end",
                    placeholder: "请选择数据时间",
                  },
                  model: {
                    value: _vm.orderFilter.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderFilter, "date", $$v)
                    },
                    expression: "orderFilter.date",
                  },
                }),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "button",
                    attrs: { type: "warning", ghost: "" },
                    on: { click: _vm.getOrderList },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-status" },
              [
                _vm.loading_orderStatus
                  ? _c("Spin", { attrs: { fix: "" } })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.orderList, function (v, i) {
                  return [
                    _c(
                      "div",
                      {
                        key: i,
                        staticClass: "type",
                        on: {
                          click: function ($event) {
                            return _vm.gotoStatus(v.filter)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(_vm._s(v.number)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(v.name)),
                        ]),
                        _vm._v(" "),
                        _c("img", { attrs: { src: v.url } }),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout second-layout" }, [
            _c("div", { staticClass: "left" }, [
              _c(
                "div",
                { staticClass: "transfer" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c("DatePicker", {
                        staticClass: "dateRange",
                        staticStyle: { "margin-left": "auto" },
                        attrs: {
                          type: "daterange",
                          options: _vm.dpOptions,
                          format: "yyyy/MM/dd",
                          placeholder: "请选择数据时间",
                        },
                        model: {
                          value: _vm.transferFilter.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.transferFilter, "date", $$v)
                          },
                          expression: "transferFilter.date",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "Button",
                        {
                          staticClass: "button",
                          attrs: { type: "warning", ghost: "" },
                          on: { click: _vm.transferTime },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.loading_transferTime
                    ? _c("Spin", { attrs: { fix: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { ref: "transferTime", staticClass: "echarts" }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "efficiency" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "switch-btn",
                        staticStyle: { "margin-left": "auto" },
                      },
                      [
                        _c(
                          "span",
                          {
                            class: _vm.currentCarousel == 0 && "active",
                            on: {
                              click: function ($event) {
                                _vm.currentCarousel = 0
                              },
                            },
                          },
                          [_vm._v("揽收")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: _vm.currentCarousel == 1 && "active",
                            on: {
                              click: function ($event) {
                                _vm.currentCarousel = 1
                              },
                            },
                          },
                          [_vm._v("下单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "Tooltip",
                          {
                            attrs: {
                              "max-width": "200",
                              content:
                                _vm.currentCarousel == 0
                                  ? _vm.explaination.collection
                                  : _vm.explaination.order,
                            },
                          },
                          [_c("Icon", { attrs: { type: "md-help-circle" } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("DatePicker", {
                      staticClass: "dateRange",
                      attrs: {
                        type: "daterange",
                        options: _vm.dpOptions,
                        format: "yyyy/MM/dd",
                        placeholder: "请选择数据时间",
                      },
                      model: {
                        value: _vm.efficiencyFilter.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.efficiencyFilter, "date", $$v)
                        },
                        expression: "efficiencyFilter.date",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "Button",
                      {
                        staticClass: "button",
                        attrs: { type: "warning", ghost: "" },
                        on: {
                          click: function ($event) {
                            _vm.efficiencyCollect()
                            _vm.efficiencyOrder()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "carousel" },
                  [
                    _vm.loading_efficiency
                      ? _c("Spin", { attrs: { fix: "" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "Carousel",
                      {
                        model: {
                          value: _vm.currentCarousel,
                          callback: function ($$v) {
                            _vm.currentCarousel = $$v
                          },
                          expression: "currentCarousel",
                        },
                      },
                      [
                        _c("CarouselItem", [
                          _c("div", {
                            ref: "efficiencyCollect",
                            staticClass: "echarts",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("CarouselItem", [
                          _c("div", {
                            ref: "efficiencyOrder",
                            staticClass: "echarts",
                          }),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "notice" }, [
                _c("div", { staticClass: "notice-title" }, [
                  _c("div", [_vm._v("公告信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.moreNotice(null)
                        },
                      },
                    },
                    [_vm._v("更多>>")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notice-content" },
                  _vm._l(_vm.noticeList, function (v, i) {
                    return _c("div", { key: i }, [
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.moreNotice(i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(v.title))]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(v.issueDate))]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "layout third-layout" },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("揽收超时订单全国热力图"),
                  ]),
                  _vm._v(" "),
                  _c("DatePicker", {
                    staticClass: "dateRange",
                    staticStyle: { "margin-left": "auto" },
                    attrs: {
                      type: "daterange",
                      options: _vm.dpOptions,
                      format: "yyyy/MM/dd",
                      placement: "bottom-end",
                      placeholder: "请选择数据时间",
                    },
                    model: {
                      value: _vm.overtimeFilter.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.overtimeFilter, "date", $$v)
                      },
                      expression: "overtimeFilter.date",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "button",
                      attrs: { type: "warning", ghost: "" },
                      on: { click: _vm.overtimeOrder },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.loading_overtime
                ? _c("Spin", { attrs: { fix: "" } })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { ref: "overtimeOrder", staticClass: "echarts" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "last-layout" }, [
            _c(
              "div",
              { staticClass: "filter" },
              [
                _c("div", { staticClass: "sub-title" }, [
                  _vm._v("运输耗时排行TOP100"),
                ]),
                _vm._v(" "),
                _c("DatePicker", {
                  staticClass: "dateRange",
                  staticStyle: { "margin-left": "auto" },
                  attrs: {
                    type: "daterange",
                    options: _vm.dpOptions,
                    format: "yyyy/MM/dd",
                    placement: "top-end",
                    placeholder: "请选择数据时间",
                  },
                  model: {
                    value: _vm.transportFilter.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.transportFilter, "date", $$v)
                    },
                    expression: "transportFilter.date",
                  },
                }),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "button",
                    attrs: { type: "warning", ghost: "" },
                    on: {
                      click: function ($event) {
                        return _vm.getTimeRanking(true)
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "transportTime" },
              [
                _vm.loading_transportTime
                  ? _c("Spin", { attrs: { fix: "" } })
                  : _vm._e(),
                _vm._v(" "),
                _c("Table", {
                  attrs: {
                    border: "",
                    columns: _vm.transportTime_column,
                    data: _vm.transportTime_data,
                  },
                }),
                _vm._v(" "),
                _c("Page", {
                  staticClass: "page",
                  attrs: {
                    total: _vm.transportPage.total,
                    current: _vm.transportPage.pageNum,
                    size: "small",
                    "show-total": "",
                  },
                  on: { "on-change": _vm.transportPageChange },
                }),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter" }, [
      _c("div", { staticClass: "sub-title" }, [_vm._v("送达效率")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter" }, [
      _c("div", { staticClass: "sub-title" }, [_vm._v("揽收与下单效率")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }