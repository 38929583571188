import orderMock from '../component/orderMock.vue';
import logisticsTrail from '../component/customizedPage/logisticsTrail.vue';
import logisticsTrailAmap from '../component/customizedPage/logisticsTrail-amap.vue';
import trajectory from '../component/Trajectory.vue';

export default [
    {
        path: '/emsModule',
        component: () => import('../component/customizedPage/emsModule.vue')
    },
    {
        path: '/orderMock',
        component: orderMock
    },
    {
        path: '/logisticsTrail',
        component: logisticsTrail
    },
    {
        path: '/wuliu_order_info_page',
        component: logisticsTrailAmap
    },
    {
        path: '/route_page',
        component: trajectory
    },
    {
        path: '/logisticsBill',
        component: () => import('../component/customizedPage/logisticsBill.vue')
    },
    {
        path: '/card',
        component: () => import('../component/customizedPage/card.vue')
    },
    {
        path: '/logisticsTrack',
        component: () => import('../component/customizedPage/logisticsTrack.vue')
    },
    {
        path: '/kd100',
        component: () => import('../component/customizedPage/LogisticsTrack/index.vue')
    },
    {
        path: '/wuliu_ad_page',
        component: () => import('../component/customizedPage/wuliuAdPage.vue')
    }
];
