export default {
  logisticsTrail: {
    component: () => import('../component/customizedPage/logisticsTrail-amap'),
    labelName: '物流轨迹'
  },
  dataStatistics: {
    component: () => import('../component/customizedPage/dataStatistics')
  },
  logisticsBill: {
    component: () => import('../component/customizedPage/logisticsBill')
  },
  operationAnalysis: {
    component: () => import('../component/customizedPage/operationAnalysis')
  },
  carrierAnalysis: {
    component: () => import('../component/customizedPage/carrierAnalysis')
  },
  templateConfig: {
    component: () => import('../component/customizedPage/templateConfig'),
    labelName: '物流产品配置'
  },
  noticeInfo: {
    component: () => import('../component/customizedPage/noticeInfo')
  },
  customerNotice: {
    component: () => import('../component/customizedPage/customerNotice'),
    labelName: '公告内容'
  },
  DataPermission: {
    component: () => import('../component/customizedPage/DataPermission')
  },
  homePage: {
    component: () => import('../component/customizedPage/homePage')
  },
  serviceAnalysis: {
    component: () => import('../component/customizedPage/serviceAnalysis')
  },
  companyWuliuAccountReport: {
    component: () => import('../component/customizedPage/companyWuliuAccountReport')
  },
  settlementReport: {
    component: () => import('../component/customizedPage/settlementReport')
  },
  carrierProfit: {
    component: () => import('../component/customizedPage/carrierProfit')
  },
  erpQuery: {
    component: () => import('../component/customizedPage/erpQuery')
  }
};
