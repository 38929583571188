<template>
    <div class="container">
        <template v-if="!isMobile">
            <div v-show="!collapse" class="logistic-content" :style="{ height: containerHeight - 40 + 'px' }">
                <div class="content-top">
                    <div class="message-item">
                        <span>快递公司：</span>
                        <span>{{ info.carrier }}</span>
                    </div>
                    <div class="message-item">
                        <span>快递号：</span>
                        <span>{{ info.mailNo }}</span>
                    </div>
                </div>
                <div class="content-bottom" :style="{ height: containerHeight - 100 + 'px' }">
                    <div class="content-title">
                        物流信息
                    </div>
                    <div :style="{ height: containerHeight - 145 + 'px', overflow: 'auto' }">
                        <Timeline>
                            <TimelineItem v-for="(item, index) in info.routeList" :key="index" color="red">
                                <div slot="dot" class="time-mark" />
                                <p class="content-detail">
                                    {{ item.remark }}
                                </p>
                                <p class="content-detail">
                                    {{ item.time }}
                                </p>
                            </TimelineItem>
                        </Timeline>
                    </div>
                </div>
            </div>
            <div class="control-bar" :class="[collapse ? 'collopse' : '']" @click="controlPane">
                <img v-if="!collapse" class="arrow" src="../../assets/image/left-arrow.png" />
                <img v-if="collapse" class="arrow" src="../../assets/image/right-arrow.png" />
            </div>
            <div id="container" class="logistic-tail"></div>
        </template>
        <template v-else>
            <div id="container" class="logistic-tail mobile"></div>
            <div class="mobile-timeline">
                <Timeline>
                    <TimelineItem v-for="(item, index) in info.routeList" :key="index" color="red">
                        <div slot="dot" class="time-mark" />
                        <p class="content-detail">
                            {{ item.remark }}
                        </p>
                        <p class="content-detail">
                            {{ item.time }}
                        </p>
                    </TimelineItem>
                </Timeline>
            </div>
        </template>
    </div>
</template>

<script>
import AMap from 'AMap';
import R3 from '@syman/burgeon-r3';
const { network } = R3;
export default {
    data() {
        return {
            center: { lng: 0, lat: 0 },
            zoom: 4,
            polylinePath: [],
            icons: [],
            lineColor: '#FD6442',
            containerHeight: '',
            info: {
                mailNo: '单号',
                carrier: '快递公司',
                senderCityLngLat: { lng: '123.13', lat: '113.21' },
                senderCity: '发送城市',
                receiverCity: '收货城市',
                receiverCityLngLat: '收货城市经纬',
                routeList: [
                    { time: '时间', city: '城市', remark: '描述' },
                    { time: '时间2', city: '城市2', remark: '描述2' }
                ],
                map: [{ lng: '123.13', lat: '113.21', city: '城市' }],
                mark: {}
            },
            active: false,
            collapse: false,
            orderid: '', // 单据id
            param: {}, // 这个是用于外链的
            isMobile: false // 是否为移动端
        };
    },
    created() {
        // 当外部系统要嵌入这个页面是 需要先给后台发送请求 后台进行校验才请求轨迹数据
        // console.log(this.$router.currentRoute, '路由');
        this.param = Object.assign({}, this.$router.currentRoute.query);
        let { client_type } = this.param;
        let ua = navigator.userAgent;
        this.isMobile = client_type === 'mobile' || ua.indexOf('Android') != -1 || ua.indexOf('iPhone') != -1;
    },
    mounted() {
        this.$nextTick(() => {
            this.containerHeight = document.getElementsByClassName('logistic-tail')[0].offsetHeight;
        });
        if (Object.keys(this.param).length > 0) {
            this.getMapDataOther();
        } else {
            this.getMapData();
        }
    },
    methods: {
        InitMap() {
            const map = new AMap.Map('container', {
                zoom: 6, // 级别
                center: [
                    this.info.senderCityLngLat ? this.info.senderCityLngLat.lng : 118.767413,
                    this.info.senderCityLngLat ? this.info.senderCityLngLat.lat : 32.041544
                ], // 中心点坐标
                viewMode: '3D' // 使用3D视图
            });
            AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.Geolocation', 'AMap.Driving'], () => {
                if (!this.isMobile) {
                    // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
                    map.addControl(new AMap.ToolBar());
                    // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
                    map.addControl(
                        new AMap.Scale({
                            position: 'RT'
                        })
                    );
                    // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
                    map.addControl(
                        new AMap.Geolocation({
                            showButton: true, // 显示定位按钮，默认：true
                            buttonPosition: 'RB' // 定位按钮停靠位置，默认：'LB'，左下角
                        })
                    );
                }
                // 绘制路线
                var polyline = new AMap.Polyline({
                    path: this.polylinePath, // 设置线覆盖物路径
                    strokeColor: '#FD6442', // 线颜色
                    strokeOpacity: 1, // 线透明度
                    strokeWeight: 2, // 线宽
                    strokeStyle: 'solid', // 线样式
                    strokeDasharray: [10, 5], // 补充线样式
                    geodesic: true // 绘制大地线
                });
                polyline.setMap(map);
            });
            if (this.info.mark && Object.keys(this.info.mark).length > 0) {
                const ztMarker = new AMap.Marker({
                    position: [this.info.mark.lng, this.info.mark.lat] // 位置
                }); // 当前到达的点
                map.add(ztMarker);

                ztMarker.setLabel({
                    offset: new AMap.Pixel(-50, -40), // 设置文本标注偏移量
                    content: `<div class="label-container arrive">
                                <img
                                class="send"
                                src="/static/image/logistic-status.png"
                                ></img>
                                <div>
                                <span class="city">${this.info.mark.city}</span>
                                <span class="time">${this.info.mark.time}到达</span>
                                </div>
                            </div>`, // 设置文本标注内容
                    direction: 'top' // 设置文本标注方位
                });
            }
            if (this.info.senderCityLngLat) {
                const senderCityMarker = new AMap.Marker({
                    position: [this.info.senderCityLngLat.lng, this.info.senderCityLngLat.lat] // 位置
                });
                map.add(senderCityMarker);
                senderCityMarker.setLabel({
                    offset: new AMap.Pixel(-50, -30), // 设置文本标注偏移量
                    content: ` <div class="label-container">
                                    <span class="label-tip">发</span>
                                    <span class="city">${this.info.senderCity}</span>
                                </div>`, // 设置文本标注内容
                    direction: 'top' // 设置文本标注方位
                });
            }
            if (this.info.receiverCityLngLat) {
                const receiverCityMarker = new AMap.Marker({
                    position: [this.info.receiverCityLngLat.lng, this.info.receiverCityLngLat.lat] // 位置
                });
                map.add(receiverCityMarker);
                receiverCityMarker.setLabel({
                    offset: new AMap.Pixel(-50, -30), // 设置文本标注偏移量
                    content: ` <div class="label-container">
                                <span class="label-tip">收</span>
                                <span class="city">${this.info.receiverCity}</span>
                            </div>`, // 设置文本标注内容
                    direction: 'top' // 设置文本标注方位
                });
            }
        },
        lngLatToContainerPx(map, lngLat) {
            const lnglat = new AMap.LngLat(lngLat.lng, lngLat.lat);
            return map.lngLatToContainer(lnglat); // 获得 Pixel 对象
        }, // 经纬度转化为像素点坐标
        getMapData() {
            const key = R3.getModuleName();
            network
                .post('/p/cs/wuliu/order/query_route_city', {
                    id: JSON.parse(sessionStorage.getItem('undataFromPageCustomizeButtonInfo'))[key].itemId
                })
                .then(res => {
                    if (res.data.code === 0) {
                        this.info = res.data.data;
                        if (res.data.data.map.length > 0 && res.data.data.routeList.length > 0) {
                            this.polylinePath = res.data.data.map.map(item => [item.lng, item.lat]);
                            if (res.data.data.map[res.data.data.map.length - 1].city !== res.data.data.receiverCity) {
                                // 物流显示还没有收货
                                const findIndex = res.data.data.routeList.findIndex(
                                    item => item.city === res.data.data.map[res.data.data.map.length - 1].city
                                );
                                this.info.mark = Object.assign(
                                    res.data.data.map[res.data.data.map.length - 1],
                                    res.data.data.routeList[findIndex]
                                );
                            }
                        }
                        this.InitMap();
                    }
                });
        },
        getMapDataOther() {
            // url里没有order_id，取request_id作为order_id
            if (!this.param.order_id && this.param.request_id) {
                this.param.order_id = this.param.request_id;
            }
            network.post('/wuliu-app/query_route_city', this.param).then(res => {
                if (res.data.code === 0) {
                    this.info = res.data.data;
                    if (res.data.data.map.length > 0 && res.data.data.routeList.length > 0) {
                        this.polylinePath = res.data.data.map.map(item => [item.lng, item.lat]);
                        if (res.data.data.map[res.data.data.map.length - 1].city !== res.data.data.receiverCity) {
                            // 物流显示还没有收货
                            const findIndex = res.data.data.routeList.findIndex(
                                item => item.city === res.data.data.map[res.data.data.map.length - 1].city
                            );
                            this.info.mark = Object.assign(
                                res.data.data.map[res.data.data.map.length - 1],
                                res.data.data.routeList[findIndex]
                            );
                        }
                    }
                    this.InitMap();
                }
            });
        }, // 用于页面内嵌入别的系统的请求
        controlPane() {
            this.collapse = !this.collapse;
        }
    }
};
</script>

<style lang="less">
.amap-marker-label {
    padding: 0;
}
.container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    .logistic-content {
        width: 309px;
        height: 400px;
        background: #fff;
        .content-top {
            padding: 7px 20px;
            border-bottom: 1px solid rgba(236, 236, 236, 1);
            .message-item {
                font-size: 12px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(78, 78, 78, 1);
                line-height: 20px;
            }
        }
        .content-bottom {
            box-sizing: border-box;
            padding: 10px 23px;
            overflow: auto;
            .content-title {
                margin-bottom: 5px;
                font-size: 12px;
                font-weight: bold;
                color: rgba(78, 78, 78, 1);
                line-height: 20px;
            }
            .burgeon-timeline-item-head-custom {
                left: 1px;
            }
            .content-detail {
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(182, 182, 182, 1);
                line-height: 20px;
            }
            .time-mark {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: rgba(253, 100, 66, 1);
                border: 2px solid rgba(253, 100, 66, 0.2);
            }
        }
    }
    .control-bar {
        position: absolute;
        top: 50%;
        margin-top: -40px;
        left: 309px;
        width: 20px;
        height: 80px;
        box-sizing: border-box;
        background-image: url('../../assets/image/controlBar.png');
        background-size: contain;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        .arrow {
        }
    }
    .collopse {
        left: 0;
    }
    .logistic-tail {
        flex: 1;
        height: 100%;
        .label-container {
            position: absolute;
            background: #fff;
            white-space: nowrap;
            display: flex;
            &.arrive {
                bottom: -5px;
            }
            .label-tip {
                display: inline-block;
                background: rgba(253, 100, 66, 1);
                border-radius: 4px 0px 0px 4px;
                width: 26px;
                height: 26px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(255, 255, 255, 1);
                line-height: 26px;
                text-align: center;
            }

            .send {
                display: inline-block;
                width: 42px;
                height: 42px;
            }
            .city {
                padding: 2px 10px 2px 8px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(101, 101, 101, 1);
                line-height: 26px;
                vertical-align: top;
            }
            .time {
                display: block;
                font-size: 12px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(101, 101, 101, 1);
                line-height: 17px;
                vertical-align: bottom;
            }
        }
        &.mobile {
            height: 65vh;
        }
    }
    .mobile-timeline {
        position: fixed;
        bottom: 0;
        padding: 20px;
        height: 35vh;
        overflow-y: auto;
    }
}
</style>
