import Vue from 'vue';
import './src/utils/dateApi';
import VueDND from 'awe-dnd';
import ArkUi from '@syman/ark-ui';
import '@syman/ark-ui/dist/styles/ark-ui.css';
import '@syman/burgeon-r3/r3.publish/r3.min.css';
import '@syman/ark-ui/dist/styles/bjIconfonts/iconfont.css';
import R3 from '@syman/burgeon-r3';
import './src/assets/css/theme.less'; //支持根据不同主题引入对应的主题文
import './src/utils/getChildComponent';
import './static/ark.iconfont/iconfont.css';

// 企业Logo、Banner配置信息
import enterpriseLogo from './src/assets/image/tabLogin3.png';
import enterpriseBanner from './src/assets/image/tabLogin5.png';
import Login from './src/component/Login.vue';
// import content from './src/component/customizedPage/content';
import WelcomePage from './src/component/WelcomePage.vue';
import projectRouterConfig from './src/config/router.config';
import customizedPageConfig from './src/config/customized.page.config';
import customizedModalConfig from './src/config/customized.modal.config';
import Print from 'vue-print-nb';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 混入
import standardTableListsCustomize from './src/config/standardTableListsCustomize';


const whiteList = ['/emsModule', '/orderMock', '/wuliu_order_info_page', '/card', '/route_page', '/logisticsTrack', '/kd100', '/wuliu_ad_page']; // 不重定向白名单
const { router } = R3;
let location = whiteList.includes(window.location.pathname); // includes('emsModule');
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    next();
  } else {
    if (location) {
      next(`${window.location.pathname}${window.location.search}`);
    } else {
      next();
    }

  }
});
Vue.use(Print);
Vue.use(ArkUi);
Vue.use(VueDND);
Vue.use(ElementUI);
console.log(R3);
// Vue.config.devtools = true;
R3.launchApplication({
  image: {
    enterpriseLogo,
    enterpriseBanner
  },
  globalComponent: {
    Login,
    WelcomePage
  },
  customizeMixins: {
    standardTableListsCustomize: standardTableListsCustomize()
  },
  // contentConfig: {
  //   content
  // },
  externalModules: customizedPageConfig,
  externalModals: customizedModalConfig,
  projectRoutes: projectRouterConfig,
  quietRoutes: ['/emsModule', '/orderMock', '/wuliu_order_info_page', '/card', '/route_page', '/logisticsTrack', '/kd100', '/wuliu_ad_page'], // 针对接口报403的设置之后就不会跳到登陆页面
  enableGateWay: true,
  Version: '1.4',
  isCommonTable: false, // 是否使用通用表格 默认是使用通用表格的这样设置以后就是使用ag表格
  projectVersion: '2023.3.27', // 项目版本标记
  // enableKAQueryDataForUser: true
  filterUrlForNetworkScript: function(options) {
    if(options.url === '/wuliu-admin/p/yq/wuliu/ems/logistics_query') {
      return false
    } else {
      return true
    }
  }
});


