var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Trajectory" } }, [
    _c("div", { staticClass: "content-box" }, [
      _vm.trajectoryList === null
        ? _c("h2", { staticClass: "noRecord" }, [_vm._v(_vm._s(_vm.errTip))])
        : _c("div", { staticClass: "logistics-info" }, [
            _c("div", { staticClass: "tabTitle" }, [
              _c("div", { on: { click: _vm.changeSort } }, [
                _c("span", [_vm._v("时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("Icon", {
                      style: _vm.sort === "after" ? "color: #ff7800" : "",
                      attrs: { type: "md-arrow-dropup" },
                    }),
                    _vm._v(" "),
                    _c("Icon", {
                      style: _vm.sort === "after" ? "" : "color: #ff7800",
                      attrs: { type: "md-arrow-dropdown" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("地点和跟踪进度")]),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "logistics-table-pc" }, [
              _c(
                "tbody",
                _vm._l(_vm.computedTrajectoryList, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "row-time" }, [
                      _c("p", { staticClass: "light-word" }, [
                        _vm._v(_vm._s(item.time.slice(0, 10))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          class:
                            index ===
                            (_vm.sort === "after"
                              ? 0
                              : _vm.trajectoryList.length - 1)
                              ? "normal-color"
                              : "",
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.time.slice(11, 16)) +
                              "\n                                "
                          ),
                          _c("span", [_vm._v(_vm._s(item.week || ""))]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "row-line" }, [
                      _c("div", { staticClass: "point-line" }, [
                        index !== 0
                          ? _c("div", { staticClass: "line line-t" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "dot inMid",
                          class:
                            index ===
                            (_vm.sort === "after"
                              ? 0
                              : _vm.trajectoryList.length - 1)
                              ? "act"
                              : "",
                        }),
                        _vm._v(" "),
                        index !== _vm.trajectoryList.length - 1
                          ? _c("div", { staticClass: "line line-b" })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "row-info",
                        class:
                          index ===
                          (_vm.sort === "after"
                            ? 0
                            : _vm.trajectoryList.length - 1)
                            ? "normal-color"
                            : "",
                      },
                      [
                        item.route_status_desc
                          ? _c("span", [
                              _vm._v(
                                "[" + _vm._s(item.route_status_desc) + "]"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.remark) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "advertisement" }, [
      _c("div", { staticClass: "queryCode" }, [
        _c("img", {
          attrs: {
            src: require("../assets/image/trajectory/network2wechat.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("p", [
          _c("span", { staticClass: "bgIcon" }),
          _vm._v(
            "\n                    查找附近快递员，一键寄快递\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("../assets/image/trajectory/20211213165714.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }