// const { network } = R3
import R3 from '@syman/burgeon-r3';

const { network } = R3

export default () => ({
  mixins: [],
  methods: {
    async ExpressOrderExport() {
      this.$Modal.fcWarning({
        title: '提示',
        content: '是否进行当前页面的导出，点击后可到物流订单导出明细页面中查看进度',
        showCancel: true,
        mask: true,
        onOk: async() => {
          const params = {
            searchdata: this.searchData,
            filename: this.activeTab.label,
            filetype: ".xlsx",
            showColumnName: true,
            menu: this.activeTab.label,
          }
          try {
            const { data } = await network.post('/p/cs/wuliu/express_order_export', params);
            if (data.code === 0) {
              this.$Modal.fcSuccess({
                title: '提示',
                content: data.message,
                okText: '确认',
                mask: true,
                onOk: () => {}
              })
              // this.$Message.success(data.message)
              // let src = `/wuliu-admin/p/cs/download?filename=${data.data}`;
              // this.downloadWB(src, this.activeTab.label);
            }
          } catch {
          }
        }
      })
    },
    downloadWB(src, downloadName) {
      const aDom = document.createElement('a');
      aDom.style.display = 'none';
      if (downloadName) {
        aDom.download = downloadName;
      }
      aDom.href = src;
      aDom.target = '_blank';
      document.body.appendChild(aDom);
      aDom.click();
      document.body.removeChild(aDom);
    },
  }
});