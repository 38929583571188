<template>
    <div class="app">
        <div v-if="isAdmin" class="welcomepage"></div>
        <div v-else class="operationAnalysis">
            <div class="layout top-layout">
                <div class="filter">
                    <div class="sub-title">运单状态</div>
                    <DatePicker
                        class="dateRange"
                        style="margin-left:auto"
                        v-model="orderFilter.date"
                        type="daterange"
                        :options="dpOptions"
                        format="yyyy/MM/dd"
                        placement="bottom-end"
                        placeholder="请选择数据时间"
                    ></DatePicker>
                    <Button class="button" type="warning" ghost @click="getOrderList">查询</Button>
                </div>
                <div class="order-status">
                    <Spin fix v-if="loading_orderStatus"></Spin>
                    <template v-for="(v, i) in orderList">
                        <div class="type" @click="gotoStatus(v.filter)" :key="i">
                            <div class="number">{{ v.number }}</div>
                            <div class="name">{{ v.name }}</div>
                            <img :src="v.url" />
                        </div>
                    </template>
                </div>
            </div>
            <div class="layout second-layout">
                <div class="left">
                    <div class="transfer">
                        <div class="filter">
                            <div class="sub-title">送达效率</div>
                        </div>
                        <div class="filter">
                            <DatePicker
                                class="dateRange"
                                style="margin-left:auto"
                                v-model="transferFilter.date"
                                type="daterange"
                                :options="dpOptions"
                                format="yyyy/MM/dd"
                                placeholder="请选择数据时间"
                            ></DatePicker>
                            <Button class="button" type="warning" ghost @click="transferTime">查询</Button>
                        </div>
                        <Spin fix v-if="loading_transferTime"></Spin>
                        <div class="echarts" ref="transferTime"></div>
                    </div>
                    <div class="efficiency">
                        <div class="filter">
                            <div class="sub-title">揽收与下单效率</div>
                        </div>
                        <div class="filter">
                            <div class="switch-btn" style="margin-left:auto">
                                <span :class="currentCarousel == 0 && 'active'" @click="currentCarousel = 0">揽收</span>
                                <span :class="currentCarousel == 1 && 'active'" @click="currentCarousel = 1">下单</span>
                                <Tooltip
                                    max-width="200"
                                    :content="currentCarousel == 0 ? explaination.collection : explaination.order"
                                >
                                    <Icon type="md-help-circle" />
                                </Tooltip>
                            </div>
                            <DatePicker
                                class="dateRange"
                                v-model="efficiencyFilter.date"
                                type="daterange"
                                :options="dpOptions"
                                format="yyyy/MM/dd"
                                placeholder="请选择数据时间"
                            ></DatePicker>
                            <Button
                                class="button"
                                type="warning"
                                ghost
                                @click="
                                    efficiencyCollect();
                                    efficiencyOrder();
                                "
                                >查询</Button
                            >
                        </div>
                        <div class="carousel">
                            <Spin fix v-if="loading_efficiency"></Spin>
                            <Carousel v-model="currentCarousel">
                                <CarouselItem>
                                    <div class="echarts" ref="efficiencyCollect"></div>
                                </CarouselItem>
                                <CarouselItem>
                                    <div class="echarts" ref="efficiencyOrder"></div>
                                </CarouselItem>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="notice">
                        <div class="notice-title">
                            <div>公告信息</div>
                            <div @click="moreNotice(null)">更多>></div>
                        </div>
                        <div class="notice-content">
                            <div v-for="(v, i) in noticeList" :key="i">
                                <div></div>
                                <div @click="moreNotice(i)">{{ v.title }}</div>
                                <div>{{ v.issueDate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout third-layout">
                <div class="filter">
                    <div class="sub-title">揽收超时订单全国热力图</div>
                    <DatePicker
                        class="dateRange"
                        style="margin-left:auto"
                        v-model="overtimeFilter.date"
                        type="daterange"
                        :options="dpOptions"
                        format="yyyy/MM/dd"
                        placement="bottom-end"
                        placeholder="请选择数据时间"
                    ></DatePicker>
                    <Button class="button" type="warning" ghost @click="overtimeOrder">查询</Button>
                </div>
                <Spin fix v-if="loading_overtime"></Spin>
                <div class="echarts" ref="overtimeOrder"></div>
            </div>
            <div class="last-layout">
                <div class="filter">
                    <div class="sub-title">运输耗时排行TOP100</div>
                    <DatePicker
                        class="dateRange"
                        style="margin-left:auto"
                        v-model="transportFilter.date"
                        type="daterange"
                        :options="dpOptions"
                        format="yyyy/MM/dd"
                        placement="top-end"
                        placeholder="请选择数据时间"
                    ></DatePicker>
                    <Button class="button" type="warning" ghost @click="getTimeRanking(true)">查询</Button>
                </div>
                <div class="transportTime">
                    <Spin fix v-if="loading_transportTime"></Spin>
                    <Table border :columns="transportTime_column" :data="transportTime_data"></Table>
                    <Page
                        class="page"
                        :total="transportPage.total"
                        :current="transportPage.pageNum"
                        size="small"
                        show-total
                        @on-change="transportPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import R3 from '@syman/burgeon-r3';
import * as echarts from 'echarts';
const { network } = R3;
import { formatDate, getAllDate } from '../../utils/common';
import totalPng from '../../assets/image/orderStatus-total.png';
import cancelPng from '../../assets/image/orderStatus-cancel.png';
import failPng from '../../assets/image/orderStatus-fail.png';
import transferPng from '../../assets/image/orderStatus-transfer.png';
import waitPng from '../../assets/image/orderStatus-wait.png';
import '../../utils/china';
export default {
    name: 'homePage',
    props: ['isWelcomePage'],
    data() {
        return {
            isAdmin: true,
            loading_orderStatus: false,
            loading_transferTime: false,
            loading_efficiency: false,
            loading_overtime: false,
            loading_transportTime: false,
            noticeList: [],
            orderList: [],
            orderFilter: { date: [new Date(new Date() - 6 * (24 * 3600 * 1000)), new Date()] },
            orderName: {
                orderNumber: {
                    name: '订单总数',
                    url: totalPng,
                    filter: {}
                },
                cancelNumber: {
                    name: '撤销单数',
                    url: cancelPng,
                    filter: {
                        ORDER_STATUS: ['3']
                    }
                },
                abnormalNumber: {
                    name: '异常单数',
                    filter: {
                        ABNORMAL: ['Y']
                    }
                },
                failNumber: {
                    name: '失败单数',
                    url: failPng,
                    filter: {
                        ORDER_STATUS: ['5']
                    }
                },
                waitSendNumber: {
                    name: '待配送单数',
                    url: waitPng,
                    filter: {
                        ORDER_STATUS: ['1']
                    }
                },
                transferNumber: {
                    name: '转运单数',
                    url: transferPng,
                    filter: {
                        ORDER_STATUS: ['9', '10']
                    }
                }
            },
            dpOptions: {
                // 快捷选项
                shortcuts: [
                    {
                        text: '最近一周',
                        value() {
                            const start = new Date();
                            const end = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近一个月',
                        value() {
                            const start = new Date();
                            const end = new Date();
                            start.setMonth(start.getMonth() - 1);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近三个月',
                        value() {
                            const start = new Date();
                            const end = new Date();
                            start.setMonth(start.getMonth() - 3);
                            return [start, end];
                        }
                    }
                ],
                // 禁止选择日期
                disabledDate(date) {
                    return date > new Date();
                }
            },
            transferTimeOption: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/> {b} : {c} ({d}%)'
                },
                legend: {
                    bottom: '0%',
                    left: 'center'
                },
                series: [
                    {
                        name: '到达时间',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        roseType: 'radius',
                        itemStyle: {
                            borderRadius: 20,
                            borderWidth: 2,
                            normal: {
                                color: function(params) {
                                    var colorList = [
                                        {
                                            c1: ' #8CBAFF',
                                            c2: '#669AFF'
                                        },
                                        {
                                            c1: ' #FFC683',
                                            c2: '#FDBA6B'
                                        },
                                        {
                                            c1: '#FF9988',
                                            c2: '#FF664D'
                                        }
                                    ];
                                    return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                        {
                                            offset: 0,
                                            color: colorList[params.dataIndex].c1
                                        },
                                        {
                                            offset: 1,
                                            color: colorList[params.dataIndex].c2
                                        }
                                    ]);
                                }
                            }
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            },
            transferFilter: { date: [new Date(new Date() - 6 * (24 * 3600 * 1000)), new Date()] },
            currentCarousel: 0,
            efficiencyCollectOption: {
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        let marker = `<span style="display:inline-block;
                                        margin-right:5px;border-radius:10px;
                                        width:10px;height:10px;
                                        background-color:${params.color.colorStops[0].color};"></span>`;
                        let res = `<div>
                            <div>${params.seriesName}</div>
                            <div>${marker} ${params.name}：${params.value} ${
                            params.seriesName !== '超时率' ? '单' : '%'
                        }</div>
                        </div>`;
                        return res;
                    }
                },
                grid: {
                    y2: 30
                },
                legend: {
                    top: 20,
                    itemWidth: 15,
                    itemHeight: 15,
                    data: ['及时揽收', '非及时揽收', '超时率']
                },
                xAxis: {
                    data: [],
                    splitLine: {
                        show: false
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '数量',
                        splitLine: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        name: '比例',
                        splitLine: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '及时揽收',
                        type: 'bar',
                        barMaxWidth: 24,
                        stack: '使用情况',
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#58BFFF'
                                    },
                                    {
                                        offset: 1,
                                        color: '#1EA9FF'
                                    }
                                ])
                            }
                        }
                    },
                    {
                        name: '非及时揽收',
                        type: 'bar',
                        barMaxWidth: 24,
                        stack: '使用情况',
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#FFCB67'
                                    },
                                    {
                                        offset: 1,
                                        color: '#FFAA46'
                                    }
                                ])
                            }
                        }
                    },
                    {
                        name: '超时率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#FF9988'
                                    },
                                    {
                                        offset: 1,
                                        color: '#FF664D'
                                    }
                                ])
                            }
                        }
                    }
                ]
            },
            efficiencyOrderOption: {
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        let marker = `<span style="display:inline-block;
                                        margin-right:5px;border-radius:10px;
                                        width:10px;height:10px;
                                        background-color:${params.color.colorStops[0].color};"></span>`;
                        let res = `<div>
                            <div>${params.seriesName}</div>
                            <div>${marker} ${params.name}：${params.value} ${
                            params.seriesName !== '超时率' ? '单' : '%'
                        }</div>
                        </div>`;
                        return res;
                    }
                },
                grid: {
                    y2: 30
                },
                legend: {
                    top: 20,
                    itemWidth: 15,
                    itemHeight: 15,
                    data: ['及时下单', '非及时下单', '超时率']
                },
                xAxis: {
                    data: [],
                    splitLine: {
                        show: false
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '数量',
                        splitLine: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        name: '比例',
                        splitLine: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '及时下单',
                        type: 'bar',
                        barMaxWidth: 24,
                        stack: '使用情况',
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#58BFFF'
                                    },
                                    {
                                        offset: 1,
                                        color: '#1EA9FF'
                                    }
                                ])
                            }
                        }
                    },
                    {
                        name: '非及时下单',
                        type: 'bar',
                        barMaxWidth: 24,
                        stack: '使用情况',
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#FFCB67'
                                    },
                                    {
                                        offset: 1,
                                        color: '#FFAA46'
                                    }
                                ])
                            }
                        }
                    },
                    {
                        name: '超时率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [],
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#FF9988'
                                    },
                                    {
                                        offset: 1,
                                        color: '#FF664D'
                                    }
                                ])
                            }
                        }
                    }
                ]
            },
            efficiencyFilter: { date: [new Date(new Date() - 6 * (24 * 3600 * 1000)), new Date()] },
            explaination: {
                collection: '',
                order: ''
            },
            overtimeOrderOptions: {
                tooltip: {
                    trigger: 'item'
                },
                geo: {
                    map: 'china',
                    zoom: 1.2,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    itemStyle: {
                        normal: {
                            areaColor: '#3293FF',
                            borderColor: '#CAE3FF',
                            borderWidth: 0.5
                        },
                        emphasis: {
                            areaColor: '#CAE3FF',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 5,
                            borderWidth: 0
                        }
                    }
                },
                series: [
                    {
                        name: '异常订单',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: function(val) {
                                if (val > 0 && val <= 10) {
                                    return 10;
                                } else if (val > 10 && val < 50) {
                                    return 20;
                                } else if (val > 50 && val < 100) {
                                    return 30;
                                } else {
                                    return 40;
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 200, 100, 0.8)'
                                }
                            },
                            label: {
                                formatter: '{b}',
                                position: 'right',
                                show: false
                            },
                            data: []
                        }
                    }
                    // {
                    //     name: '异常订单',
                    //     type: 'scatter',
                    //     coordinateSystem: 'geo',
                    //     markPoint: {
                    //         symbol: 'circle',
                    //         symbolSize: function(val) {
                    //             if (val > 0 && val <= 10) {
                    //                 return 10;
                    //             } else if (val > 10 && val < 50) {
                    //                 return 20;
                    //             } else if (val > 50 && val < 100) {
                    //                 return 30;
                    //             } else {
                    //                 return 40;
                    //             }
                    //         },
                    //         itemStyle: {
                    //             normal: {
                    //                 color: 'rgba(255, 129, 108, 0.8)'
                    //             }
                    //         },
                    //         label: {
                    //             formatter: '{b}',
                    //             position: 'right',
                    //             show: false
                    //         },
                    //         data: [
                    //             {
                    //                 name: '北京市',
                    //                 value: 9,
                    //                 coord: [116.405285, 39.904989]
                    //             },
                    //             {
                    //                 name: '晋中市',
                    //                 value: 19,
                    //                 coord: [112.736465, 37.696495]
                    //             },
                    //             {
                    //                 name: '呼和浩特市',
                    //                 value: 23,
                    //                 coord: [111.670801, 40.81831]
                    //             }
                    //         ]
                    //     }
                    // }
                ]
            },
            overtimeFilter: { date: [new Date(new Date() - 6 * (24 * 3600 * 1000)), new Date()] },
            transportTime_column: [
                {
                    title: '排名',
                    key: 'ranking',
                    render: (h, params) => {
                        let index = (this.transportPage.pageNum - 1) * 10 + params.index + 1;
                        return h('span', index);
                    }
                },
                {
                    title: '订单号',
                    key: 'erpOrderId'
                },
                {
                    title: '店仓',
                    key: 'storeName'
                },
                {
                    title: '快递产品',
                    key: 'accountName'
                },
                {
                    title: '发货地',
                    key: 'senderCity'
                },
                {
                    title: '收货地',
                    key: 'receiverCity'
                },
                {
                    title: '耗时(小时)',
                    key: 'carriageTime'
                }
            ],
            transportTime_data: [],
            transportFilter: { date: [new Date(new Date() - 6 * (24 * 3600 * 1000)), new Date()] },
            transportPage: {
                total: 100,
                pageNum: 1
            }
        };
    },
    created() {
        // 判断是否为管理员
        const url = this.isWelcomePage ? '/wuliu-admin/p/cs/wuliu/users/is_admin' : '/p/cs/wuliu/users/is_admin';
        network.post(url, {}).then(res => {
            this.isAdmin = res.data.data.isAdmin === 'Y';
            if (!this.isAdmin) {
                this.getOrderList();
                this.transferTime();
                this.efficiencyCollect();
                this.efficiencyOrder();
                this.overtimeOrder();
                this.getNotice();
                this.getTimeRanking();
            }
        });
    },
    methods: {
        // 获取运单状态
        getOrderList() {
            let params = {
                startDate: formatDate(this.orderFilter.date[0]),
                endDate: formatDate(this.orderFilter.date[1])
            };
            this.loading_orderStatus = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_company_order_status_number'
                : '/p/cs/wuliu/order/query_company_order_status_number';
            network.post(url, params).then(res => {
                this.loading_orderStatus = false;
                let { data } = res.data;
                this.orderList = data.map(v => {
                    return {
                        ...v,
                        name: this.orderName[v.name].name,
                        url: this.orderName[v.name].url,
                        filter: this.orderName[v.name].filter
                    };
                });
            });
        },
        // 页面跳转
        gotoStatus(filter) {
            const id = JSON.parse(localStorage.getItem('userInfo')).id;
            const CREATIONDATE = [
                formatDate(this.orderFilter.date[0], '/') + ' 00:00:00',
                formatDate(this.orderFilter.date[1], '/') + ' 23:59:59'
            ];
            const data = {
                ...filter,
                CREATIONDATE,
                R3UserId: `${id}_EXPRESS_ORDER_XU_YUNYING`
            };
            indexedDBApi.addSearch(data);
            vm.$store.commit('global/tabOpen', {
                type: 'S',
                url: '/SYSTEM/TABLE/EXPRESS_ORDER_XU_YUNYING/10194'
            });
        },
        // 初始化echarts
        initChart(element, option) {
            let myChart = echarts.init(element);
            myChart.setOption(option);
        },
        // 送达效率
        transferTime() {
            let params = {
                startDate: formatDate(this.transferFilter.date[0]),
                endDate: formatDate(this.transferFilter.date[1])
            };
            this.loading_transferTime = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_carriage_time'
                : '/p/cs/wuliu/order/query_carriage_time';
            network.post(url, params).then(res => {
                this.loading_transferTime = false;
                this.transferTimeOption.series[0].data = [
                    { value: res.data.data.oneDay, name: '<=24小时' },
                    { value: res.data.data.twoDay, name: '>24且<=48小时' },
                    { value: res.data.data.moreThanTwoDay, name: '>48小时' }
                ];
                let element = this.$refs.transferTime;
                this.initChart(element, this.transferTimeOption);
            });
        },
        // 效率统计-揽收
        efficiencyCollect() {
            let params = {
                startDate: formatDate(this.efficiencyFilter.date[0]),
                endDate: formatDate(this.efficiencyFilter.date[1])
            };
            this.loading_efficiency = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_abnormal'
                : '/p/cs/wuliu/order/query_abnormal';
            network.post(url, params).then(res => {
                this.loading_efficiency = false;
                this.efficiencyCollectOption.xAxis.data = getAllDate(params.startDate, params.endDate);
                this.efficiencyCollectOption.series[0].data = res.data.data.normalNumber;
                this.efficiencyCollectOption.series[1].data = res.data.data.abnormalNumber;
                this.efficiencyCollectOption.series[2].data = res.data.data.abnormalRate;
                this.explaination.collection = `及时揽收：${res.data.data.normalNumberExplain}
                                                非及时揽收：${res.data.data.abnormalNumberExplain}
                                                超时率：${res.data.data.abnormalRateExplain}`;
                let element = this.$refs.efficiencyCollect;
                this.initChart(element, this.efficiencyCollectOption);
            });
        },
        // 效率统计-下单
        efficiencyOrder() {
            let params = {
                startDate: formatDate(this.efficiencyFilter.date[0]),
                endDate: formatDate(this.efficiencyFilter.date[1])
            };
            this.loading_efficiency = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_placing_abnormal'
                : '/p/cs/wuliu/order/query_placing_abnormal';
            network.post(url, params).then(res => {
                this.loading_efficiency = false;
                this.efficiencyOrderOption.xAxis.data = getAllDate(params.startDate, params.endDate);
                this.efficiencyOrderOption.series[0].data = res.data.data.normalNumber;
                this.efficiencyOrderOption.series[1].data = res.data.data.abnormalNumber;
                this.efficiencyOrderOption.series[2].data = res.data.data.abnormalRate;
                this.explaination.order = `及时下单：${res.data.data.normalNumberExplain}
                                                非及时下单：${res.data.data.abnormalNumberExplain}
                                                超时率：${res.data.data.abnormalRateExplain}`;
                let element = this.$refs.efficiencyOrder;
                this.initChart(element, this.efficiencyOrderOption);
            });
        },
        // 超时订单
        overtimeOrder() {
            let params = {
                startDate: formatDate(this.overtimeFilter.date[0]),
                endDate: formatDate(this.overtimeFilter.date[1])
            };
            this.loading_overtime = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/company_city_abnormal_order'
                : '/p/cs/wuliu/order/company_city_abnormal_order';
            network.post(url, params).then(res => {
                this.overtimeOrderOptions.series[0].markPoint.data = res.data.data.map(v => {
                    this.loading_overtime = false;
                    return {
                        name: v.cityName,
                        value: v.abnormalCount,
                        coord: [v.lng, v.lat]
                    };
                });
                let element = this.$refs.overtimeOrder;
                this.initChart(element, this.overtimeOrderOptions);
            });
        },
        // 获取公告信息
        getNotice() {
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_company_notice'
                : '/p/cs/wuliu/order/query_company_notice';
            network.post(url, {}).then(res => {
                this.noticeList = res.data.data;
            });
        },
        // 更多公告信息
        moreNotice(i) {
            if (i !== null) {
                localStorage.setItem('noticeIndex', i);
            } else {
                localStorage.removeItem('noticeIndex');
            }
            vm.$store.commit('global/tabOpen', {
                type: 'C',
                url: '/CUSTOMIZED/CUSTOMERNOTICE/2279'
            });
        },
        // 运输耗时排名
        getTimeRanking(init) {
            if (init) {
                this.transportPage.pageNum = 1;
            }
            let params = {
                startDate: formatDate(this.transportFilter.date[0]),
                endDate: formatDate(this.transportFilter.date[1]),
                pageNum: this.transportPage.pageNum,
                pageSize: 10
            };
            this.loading_transportTime = true;
            const url = this.isWelcomePage
                ? '/wuliu-admin/p/cs/wuliu/order/query_time_ranking_list'
                : '/p/cs/wuliu/order/query_time_ranking_list';
            network.post(url, params).then(res => {
                this.loading_transportTime = false;
                this.transportPage.total = res.data.data.total > 100 ? 100 : res.data.data.total;
                this.transportTime_data = res.data.data.listArray;
            });
        },
        // 运输耗时切换页码
        transportPageChange(e) {
            this.transportPage.pageNum = e;
            this.getTimeRanking();
        }
    }
};
</script>

<style scoped lang="less">
.app {
    height: 100%;
    width: 100%;
}
.welcomepage {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../../assets/image/homePage_02.jpg') 25% 15% no-repeat;
    background-size: 85% 80%;
    background-color: #fff;
}
.operationAnalysis {
    .layout {
        margin: 0 -15px;
        border-bottom: solid 10px rgb(241, 241, 241);
    }
    .top-layout {
        .order-status {
            position: relative;
            display: flex;
            justify-content: space-between;
            height: 150px;
            margin: 40px 100px 30px;
            .type {
                flex-basis: 240px;
                flex-grow: 1;
                margin-right: 30px;
                background: url('../../assets/image/orderStatus-bg.png') no-repeat;
                background-size: 100% 100%;
                .number {
                    position: relative;
                    top: 20%;
                    left: 20%;
                    font-size: 35px;
                    color: #fff;
                }
                .name {
                    position: relative;
                    top: 20%;
                    left: 20%;
                    font-size: 15px;
                    color: #fff;
                }
                img {
                    position: relative;
                    top: -25%;
                    left: 65%;
                }
            }
            .type:hover {
                cursor: pointer;
                background: url('../../assets/image/orderStatus-bg-active.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .second-layout {
        display: flex;
        .left {
            display: flex;
            box-sizing: border-box;
            border-right: solid 10px rgb(241, 241, 241);
            width: 80%;
            .transfer {
                position: relative;
                width: 35%;
                border-right: solid 10px rgb(241, 241, 241);
                .echarts {
                    margin-bottom: 15px;
                    height: 350px;
                    width: 100%;
                }
            }
            .efficiency {
                position: relative;
                width: 65%;
                .switch-btn {
                    margin-right: 40px;
                    font-size: 14px;
                    font-weight: bold;
                    span {
                        margin: 0 6px;
                        cursor: pointer;
                    }
                    span.active {
                        color: #ffa94f;
                        border-bottom: solid 2px #ffa94f;
                    }
                }
                .carousel {
                    height: 365px;
                    width: 100%;
                    .echarts {
                        margin-bottom: 15px;
                        height: 350px;
                        width: 100%;
                    }
                }
            }
        }
        .right {
            width: 20%;
            .notice {
                padding: 20px;
                .notice-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    div:first-child {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    div:nth-child(2) {
                        font-size: 14px;
                        color: #ffa94f;
                        cursor: pointer;
                    }
                }
                .notice-content {
                    color: #b1c1c7;
                    margin-top: 15px;
                    div {
                        padding: 5px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        div:first-child {
                            width: 10px;
                            height: 10px;
                            border-radius: 10px;
                            background: #ffa94f;
                        }
                        div:nth-child(2) {
                            min-width: 0px;
                            width: 100px;
                            flex-grow: 1;
                            margin: 0 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .third-layout {
        position: relative;
        .echarts {
            margin-bottom: 15px;
            height: 750px;
            width: 100%;
        }
    }
    .last-layout {
        min-height: 350px;
        .transportTime {
            position: relative;
            padding: 30px 20px 40px;
            .page {
                margin-top: 20px;
            }
        }
    }
    .filter {
        padding: 30px 0px 0px 20px;
        display: flex;
        align-items: center;
        .sub-title {
            font-size: 18px;
            font-weight: bolder;
            color: rgba(51, 51, 51, 0.9);
        }
        .filter-text {
            font-size: 14px;
            font-weight: bold;
            padding: 0 20px 0 50px;
        }
        .dateRange {
            width: 200px;
        }
        .select {
            width: 150px;
        }
        .button {
            height: 26px;
            line-height: 14px;
            margin: 0 20px 0 30px;
        }
    }
    // select框位置bug
    /deep/.ark-select-dropdown {
        top: auto !important;
    }
}
</style>
