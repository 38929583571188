export default {
  RegisterInTransit: {
    component: () => import('../component/customizedModal/LogisterOrder/RegisterInTransit')
  },
  remark: {
    component: () => import('../component/customizedModal/LogisterOrder/remark')
  },
  RegistrationSpecialFee: {
    component: () => import('../component/customizedModal/LogisterOrder/RegistrationSpecialFee')
  },
  RegisteredCompensation: {
    component: () => import('../component/customizedModal/LogisterOrder/RegisteredCompensation')
  },
  CreateReconciliation: {
    component: () => import('../component/customizedModal/CreateReconciliation')
  },
  UploadCheckBill: {
    component: () => import('../component/customizedModal/UploadCheckBill')
  },
  UploadCustomerBill: {
    component: () => import('../component/customizedModal/UploadCustomerBill')
  },
  printBarCodePage: {
    component: () => import('../component/customizedModal/printBarCodePage/printBarCodePage')
  },
  functionConfig: {
    component: () => import('../component/customizedModal/functionConfig')
  },
  costCompany: {
    component: () => import('../component/customizedModal/costCompany')
  },
  createBill: {
    component: () => import('../component/customizedModal/createBill')
  },
  billCompute: {
    component: () => import('../component/customizedModal/billCompute')
  },
  adjustCost: {
    component: () => import('../component/customizedModal/adjustCost')
  },
  billGenerate: {
    component: () => import('../component/customizedModal/billGenerate')
  },
  confirmBill: {
    component: () => import('../component/customizedModal/confirmBill')
  },
  chargeBill: {
    component: () => import('../component/customizedModal/chargeBill')
  },
  orderUpload: {
    component: () => import('../component/customizedModal/orderUpload')
  },
  printBarCodePageList: {
    component: () => import('../component/customizedModal/printBarCodePage/printBarCodePageList')
  },
  cancelOrder: {
    component: () => import('../component/customizedModal/cancelOrder')
  },
  markTransfer: {
    component: () => import('../component/customizedModal/markTransfer')
  },
  batchSendOrder: {
    component: () => import('../component/customizedModal/batchSendOrder')
  },
  recharge: {
    component: () => import('../component/customizedModal/recharge')
  },
  erpQueryBtn: {
    component: () => import('../component/customizedModal/erpQueryBtn')
  },
  batchCancelOrder: {
    component: () => import('../component/customizedModal/batchCancelOrder')
  },
  uploadErrorWeightBill: {
    component: () => import('../component/customizedModal/uploadErrorWeightBill')
  },
  changeMailno: {
    component: () => import('../component/customizedModal/changeMailno')
  },
  getJdWayBillCode: {
    component: () => import('../component/customizedModal/getJdWayBillCode')
  },
  distributionCompany: {
    component: () => import('../component/customizedModal/distributionCompany')
  },
  noBill: {
    component: () => import('../component/customizedModal/noBill')
  },
  interceptOrder: {
    component: () => import('../component/customizedModal/interceptOrder')
  },
  distributionCustomer: {
    component: () => import('../component/customizedModal/distributionCustomer')
  },
  updateWuliuConfigInfoBtn: {
    component: () => import('../component/customizedModal/updateWuliuConfigInfoBtn')
  },
  cancelMaterial: {
    component: () => import('../component/customizedModal/cancelMaterial')
  },
  settingBillShowFields: {
    component: () => import('../component/customizedModal/settingBillShowFields')
  },
  uploadOrderByBillDate: {
    component: () => import('../component/customizedModal/uploadOrderByBillDate')
  },
  modifyOrderStatus: {
    component: () => import('../component/customizedModal/modifyOrderStatus')
  },
  modifyTrueFeeCharge: {
    component: () => import('../component/customizedModal/modifyTrueFeeCharge')
  },
  CalculateAdjustBill: {
    component: () => import('../component/customizedModal/calculateAdjustBillDialog')
  },
  BatchDistributionCustomer: {
    component: () => import('../component/customizedModal/BatchDistributionCustomer')
  },
  qywxBatchTransfer: {
    component: () => import('../component/customizedModal/qywxBatchTransfer')
  },
  decryptOrder: {
    component: () => import('../component/customizedModal/decryptOrder')
  },
  decryptOrderObject: {
    component: () => import('../component/customizedModal/decryptOrder')
  },
  ModifyAddress: {
    component: () => import('../component/customizedModal/modifyAddress')
  }
};
