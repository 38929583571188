<template>
    <div id="Trajectory">
        <div class="content-box">
            <h2 v-if="trajectoryList === null" class="noRecord">{{errTip}}</h2>
            <div v-else class="logistics-info">
                <div class="tabTitle">
                    <div @click="changeSort">
                        <span>时间</span>
                        <div>
                            <Icon :style="sort === 'after' ? 'color: #ff7800' : ''" type="md-arrow-dropup" />
                            <Icon :style="sort === 'after' ? '' : 'color: #ff7800'" type="md-arrow-dropdown" />
                        </div>
                    </div>
                    <span>地点和跟踪进度</span>
                </div>
                <table class="logistics-table-pc">
                    <tbody>
                        <tr v-for="(item, index) in computedTrajectoryList" :key="index">
                            <td class="row-time">
                                <p class="light-word">{{item.time.slice(0, 10)}}</p>
                                <p :class="index === ( sort === 'after' ? 0 : trajectoryList.length - 1 ) ?
                                 'normal-color' : ''">
                                    {{ item.time.slice(11, 16) }}
                                    <span>{{ item.week || '' }}</span>
                                </p>
                            </td>
                            <td class="row-line">
                                <div class="point-line">
                                    <div v-if="index !== 0" class="line line-t"></div>
                                    <div :class="index === ( sort === 'after' ? 0 : trajectoryList.length - 1 ) ? 'act' : ''" class="dot inMid"></div>
                                    <div v-if="index !== trajectoryList.length - 1" class="line line-b"></div>
                                </div>
                            </td>
                            <td :class="index ===
                            ( sort === 'after' ? 0 : trajectoryList.length - 1 )
                            ? 'normal-color' : ''" class="row-info"
                            >
                                <span v-if="item.route_status_desc">[{{item.route_status_desc}}]</span>
                                {{item.remark}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="advertisement">
                <div class="queryCode">
                    <img src="../assets/image/trajectory/network2wechat.png" alt="">
                    <p>
                        <span class="bgIcon"></span>
                        查找附近快递员，一键寄快递
                    </p>
                </div>
                <img src="../assets/image/trajectory/20211213165714.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';
  const { network } = R3;

  export default {
    name: 'Trajectory',
    data() {
      return {
        errTip: '抱歉，暂无查询记录...',
        sort: 'after',
        trajectoryList: [
          // {
          //   time: "2021-12-21 10:02:02",
          //   remark: "[深圳市]您的快件已签收，如有疑问请电联快递为您服务。（主单总件数：1件）",
          // },
          // {
          //   time: "2021-12-21 10:02:02",
          //   remark: "[深圳市]您的快件已签收，如有疑问请电联快递为您服务。（主单总件数：1件）",
          // },
          // {
          //   time: "2021-12-16 10:02:02",
          //   remark: "[深圳市]快件派送至【丰巢智能快递柜】,请您凭取件码至康桥花园一期6号岗15栋出入口（原e栈）领取您的快递.",
          // },
        ]
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
      let { access_code, sign, nonce_str, mail_no, carrier_code, phone  } = this.$route.query;
      let params = {
        access_code: access_code,
        sign: sign,
        nonce_str: nonce_str,
        mail_no: mail_no,
        carrier_code: carrier_code,
        phone: phone
      }
      network.post('/wuliu-app/query_order_route', params).then(res => {
        if (res.data.return_code ==='SUCCESS') {
          this.transformationWeek(res.data.route_list)
          this.trajectoryList = res.data.route_list;
        } else {
          this.trajectoryList = null;
          this.errTip = res.data.return_msg || this.errTip
        }
      });
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      changeSort() {
        this.sort = this.sort ==='after' ? 'before' : 'after'
      },
      transformationWeek(list) {
        function weekFun(dateFormat) {
          let date = new Date(dateFormat);
          let day = date.getDay();
          let weekArr = ['日', '一', '二', '三', '四', '五', '六']
          return `星期${weekArr[day]}`
        }
        let judgeShowWeek = '';
        for (let i = list.length - 1; i >= 0; i--) {
          if (judgeShowWeek !== list[i].time.slice(0, 10) || i === 0) {
            judgeShowWeek = list[i].time.slice(0, 10);
            list[i].week = weekFun(judgeShowWeek)
          }
        }
      }
    },
    watch: {},
    computed: {
      computedTrajectoryList() {
        let list = [];
        if (this.sort ==='after') {
          list = this.trajectoryList;
        } else {
          for (let i = this.trajectoryList.length - 1; i >= 0; i--) {
            list.push(this.trajectoryList[i])
          }
        }
        return list;
      }
    },
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    @media (max-width: 919px) {
        .content-box {
            width: auto;
            .advertisement {
                display: none;
            }
        }
    }
    @media (min-width: 920px) {
        .content-box {
            width: 920px;
            border: 1px solid #d2d2d2;
            .logistics-info {
                width: 600px;
            }
        }
    }
    #Trajectory {
        width: 100%;
        height: 100%;
        overflow: auto;
        .content-box {
            margin: auto;
            padding: 0 10px 20px 10px;
            display: flex;
            justify-content: space-between;
            .noRecord {
                width: 100%;
                text-align: center;
                padding-top: 12px;
            }
            .logistics-info {
                .tabTitle {
                    height: 43px;
                    line-height: 43px;
                    font-size: 16px;
                    display: flex;
                    >div:first-child {
                        width: 105px;
                        text-align: center;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        >div {
                            display: flex;
                            flex-direction: column;
                            .iconmd-arrow-dropup {
                                margin-top: 3px;
                            }
                            .iconmd-arrow-dropdown {
                                margin-top: -8px;
                            }
                        }
                    }
                    >span:nth-child(2) {
                        padding-left: 52px;
                    }
                }
                .logistics-table-pc {
                    color: #828282;
                    border-collapse: collapse;
                    .normal-color {
                        color: #ff7800;
                    }
                    tr {
                        .row-time {
                            width: 105px;
                            padding: 6px 0;
                            text-align: center;
                            line-height: 22px;
                            color: #828282;
                            .light-word {
                                color: #cccccc;
                            }
                        }
                        .row-line {
                            width: 50px;
                            position: relative;
                        }
                        .row-info {
                            padding: 6px;
                            line-height: 22px;
                        }
                    }
                }
            }
            .advertisement {
                width: 250px;
                .queryCode {
                    margin-top: 20px;
                    border: 1px solid #efeff4;
                    font-size: 16px;
                    color: #333;
                    text-align: center;
                    >img {
                        width: 150px;
                        margin: 20px;;
                    }
                    >p {
                        padding: 8px;
                        background: #efeff4;
                    }
                }
                img {
                    width: 250px;
                    margin-top: 20px;
                }
            }
        }
    }
    .point-line {
        width: 100%;
        height: 100%;
        margin-left: 1px;
        position: absolute;
        top: 0;
        left: 0;
        .line {
            width: 1px;
            background: #d2d2d2;
            height: 50%;
            position: absolute;
            left: 50%;
        }
        .line-t {
            top: 0;
        }
        .line-b {
            bottom: 0;
        }
        .inMid {
            position: absolute;
            left: 50%;
            margin-left: -4px;
            top: 50%;
            margin-top: -4px;
            z-index: 9;
        }
        .dot {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #c3c3c3;
            box-shadow: 0 0 0 2px rgba(195, 195, 195, 0.2);
        }
        .dot.act {
            /*background: #317ee7;*/
            /*box-shadow: 0 0 0 2px rgba(49, 126, 231, 0.2);*/
            background: #ff7800;
            box-shadow: 0 0 0 2px rgba(255, 120, 0, 0.2);
        }

    }

    .bgIcon {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        vertical-align: middle;
        background: url('../assets/image/trajectory/wechat.png');
    }
</style>
