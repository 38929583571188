/* eslint-disable space-infix-ops */
//下单模拟页面
<template>
  <div class="order-mock">
    <div class="form-area">
      <div class="head-area">
        <div class="title">
          物流下单信息填写
        </div>
        <div class="mean-area">
          <span class="mean-item">
            带<span class="require-mark">*</span>选项必须填写
          </span>
          <span class="mean-item">
            带<span class="readonly" />不可编辑
          </span>
        </div>
        <div
          class="title-login"
          @click="goLogin"
        >
          登陆
        </div>
      </div>
      <div class="line" />
      <div class="form-content">
        <div
          v-for="(item,index) in formList"
          :key="index"
          class="form-item"
        >
          <div
            v-if="item.mutiple===false"
            class="form-label"
          >
            <span
              v-if="item.required&&!item.readonly"
              class="require-mark"
            >*</span>
            <span
              v-if="item.readonly&&!item.required"
              class="readonly"
            />
            {{ item.label }}:
          </div>
          <div
            v-if="item.mutiple===true&&item.children"
            class="form-label form-column"
          >
            <div
              v-for="(child,childIndex) in item.children"
              :key="childIndex"
              class="form-column-tw0"
            >
              <span
                v-if="item.required"
                class="require-mark"
              >*</span>
              {{ child.label }}:
            </div>
          </div>
          <div
            v-if="item.type==='input'"
            class="form-item-detail"
          >
            <Input v-model="item.value" />
          </div>
          <div
            v-if="item.type==='select'&&item.mutiple===false"
            class="form-item-detail"
          >
            <Select v-model="item.value">
              <Option
                v-for="(inner,innerIndex) in item.props.options"
                :key="innerIndex"
                :value="inner.value"
              >
                {{ inner.title }}
              </Option>
            </Select>
          </div>
          <div
            v-if="item.type==='select'&&item.mutiple===true"
            class="form-item-detail"
          >
            <div
              v-for="(child,childIndex) in item.children"
              :key="childIndex"
              class="form-column-detail"
            >
              <Select
                v-model="child.value"
                @on-change="onChange($event,index,childIndex)"
              >
                <Option
                  v-for="(inner,innerIndex) in child.props.options"
                  :key="innerIndex"
                  :value="inner.title"
                >
                  {{ inner.title }}
                </Option>
              </Select>
            </div>
          </div>
          <div
            v-if="item.type==='text'"
            class="form-item-detail"
          >
            <span class="form-text">{{ item.value }}</span>
            <span
              v-if="item.button"
              class="operate"
              @click="createBillNo(index)"
            >生成单号</span>
          </div>
          <div
            v-if="item.type==='date'"
            class="form-item-detail"
          >
            <DatePicker
              :value="item.value"
              type="date"
              placeholder="请选择"
              @on-change="dateChange($event,index)"
            />
          </div>
          <div
            v-if="item.type==='daterange'"
            class="form-item-detail"
          >
            <DatePicker
              :value="item.value"
              type="daterange"
              placeholder="请选择"
              @on-change="dateChange($event,index)"
            />
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="btn-area">
        <Button
          type="error"
          @click="getSubmit"
        >
          提交
        </Button>
      </div>
    </div>
    <div class="result-area">
      <div class="head-area">
        <div class="title">
          下单结果
        </div>
      </div>
      <div class="line" />
      <div class="result-item">
        <div class="result-label">
          <span class="readonly" />
          下单结果:
        </div>
        <div class="result-show">
          {{ result }}
        </div>
      </div>
      <div class="result-item">
        <div class="result-label">
          <span class="readonly" />
          响应报文:
        </div>
        <div class="result-show">
          {{ response }}
        </div>
      </div>
      <div
        v-if="barcode"
        class="result-item"
      >
        <div class="result-label">
          <span class="readonly" />
          打印条码:
        </div>
        <div class="result-show">
          <img
            class="barCode"
            :src="`http://admin-test.wuliu.piplus.cn/wuliu-app/generate_barcode?barcode=${barcode}`"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';
  import md5 from 'js-md5';

  const { network } = R3;
  export default {
    name: 'OrderMock',
    data() {
      return {
        formList: [
          {
            label: '店仓名称',
            type: 'select',
            value: '2001',
            field: 'store_name',
            required: true,
            mutiple: false,
            props: {
              options: [
               
              ]
            }
          },
          {
            label: '品牌名称',
            type: 'select',
            value: '2001',
            field: 'brand_name',
            required: true,
            mutiple: false,
            props: {
              options: [
                
              ]
            }
          },
          {
            label: '管理大区',
            type: 'select',
            value: '2001',
            field: 'region_name',
            required: true,
            mutiple: false,
            props: {
              options: [
               
              ]
            }
          },
          {
            label: '发件公司',
            type: 'input',
            value: '伯俊软件',
            field: 'sender_company',
            required: true,
            mutiple: false
          },
          {
            label: '收件公司',
            type: 'input',
            value: '伯俊软件',
            field: 'receiver_company',
            required: true,
            mutiple: false
          },
          {
            label: '发件联系人',
            type: 'input',
            value: '张三',
            field: 'sender_contact',
            required: true,
            mutiple: false
          },
          {
            label: '收件联系人',
            type: 'input',
            value: '前台',
            field: 'receiver_contact',
            required: true,
            mutiple: false
          },
          {
            label: '发件人电话',
            type: 'input',
            value: '17366360819',
            field: 'sender_tel',
            required: true,
            mutiple: false
          },
          {
            label: '收件人电话',
            type: 'input',
            value: '17366360819',
            field: 'receiver_tel',
            required: true,
            mutiple: false
          },
          {
            label: '发件人手机',
            type: 'input',
            value: '17366360819',
            field: 'sender_mobile',
            mutiple: false
          },
          {
            label: '收件人手机',
            type: 'input',
            value: '17366360819',
            field: 'receiver_mobile',
            mutiple: false
          },
          {
            mutiple: true,
            type: 'select',
            required: true,
            children: [
              {
                label: '发件省份',
                value: 320000,
                field: 'sender_province',
                props: {
                  options: [
                    {
                      title: '江苏省',
                      value: 320000
                    }
                  ]
                }
              },
              {
                label: '发件城市',
                value: 320100,
                field: 'sender_city',
                props: {
                  options: [
                    {
                      title: '南京市',
                      value: 320100
                    }
                  ]
                }
              },
              {
                label: '发件区',
                value: 320100,
                field: 'sender_county',
                props: {
                  options: [
                    {
                      title: '雨花台区',
                      value: 320100
                    }
                  ]
                }
              }
            ]
          },
          {
            mutiple: true,
            type: 'select',
            required: true,
            children: [
              {
                label: '收件省份',
                value: 310000,
                field: 'receiver_province',
                props: {
                  options: [
                    {
                      title: '上海市',
                      value: 310000
                    }
                  ]
                }
              },
              {
                label: '收件城市',
                value: 310100,
                field: 'receiver_city',
                props: {
                  options: [
                    {
                      title: '上海',
                      value: 310100
                    }
                  ]
                }
              },
              {
                label: '收件区',
                value: 310101,
                field: 'receiver_county',
                props: {
                  options: [
                    {
                      title: '虹桥区',
                      value: 310101
                    }
                  ]
                }
              }
            ]
          },
          {
            label: '发件地址',
            type: 'input',
            value: '江苏省南京市雨花台区大数据产业地180号',
            field: 'sender_address',
            required: true,
            mutiple: false
          },
          {
            label: '收件地址',
            type: 'input',
            value: '上海路123号',
            field: 'receiver_address',
            required: true,
            mutiple: false
          },
          {
            label: ' 物流产品编号',
            type: 'select',
            value: '2001',
            field: 'product_id',
            required: true,
            mutiple: false,
            props: {
              options: [
                {
                  title: '顺丰快递产品',
                  value: '1001'
                },
                {
                  title: '韵达快递产品',
                  value: '2001'
                }, {
                  title: '韵达快运产品',
                  value: '2002'
                }, {
                  title: '中通快递产品',
                  value: '3001'
                }, {
                  title: '中通快运产品',
                  value: '3002'
                }, {
                  title: '德邦物流',
                  value: '4001'
                }, {
                  title: '圆通快递产品',
                  value: '5001'
                }, {
                  title: '圆通快运产品',
                  value: '5002'
                }, {
                  title: '申通快递产品',
                  value: '6001'
                }, {
                  title: '申通快运产品',
                  value: '6002'
                }, {
                  title: '百世汇通快递产品',
                  value: '7001'
                }, {
                  title: '百世汇通快运产品',
                  value: '7002'
                }
              ]
            }
          },
          {
            label: '客户结算账号',
            type: 'input',
            value: 'za20200317',
            field: 'account_no',
            required: true,
            mutiple: false
          },
          {
            label: '货物名称',
            type: 'input',
            value: '12',
            field: 'cargo_name',
            mutiple: false,
            required: true
          },
          {
            label: '货物重量',
            type: 'input',
            value: '12',
            field: 'cargo_total_weight',
            mutiple: false
          //  required: true,
          },
          {
            label: '货物数量',
            type: 'input',
            value: '2',
            field: 'cargo_quantity',
            mutiple: false,
            required: true
          },
          {
            label: '保价金额',
            type: 'input',
            value: '2',
            field: 'insure',
            mutiple: false
          }, {
            label: '包裹数量',
            type: 'input',
            value: '1',
            field: 'parcel_quantity',
            mutiple: false
          },
          {
            label: '预期取货时间',
            type: 'date',
            value: '',
            field: 'expect_send_time',
            //  required: true,
            mutiple: false
          },
          {
            label: '客户接入码',
            type: 'input',
            value: '001',
            field: 'access_code',
            mutiple: false
          },
          {
            label: '系统编码',
            type: 'input',
            value: 'wing',
            field: 'system_source',
            mutiple: false
          }, {
            label: '随机字符串',
            type: 'input',
            value: 'ibuaiVcKdpRxkhJA',
            field: 'nonce_str',
            mutiple: false,
            required: true

          }, {
            label: '接入秘钥',
            type: 'input',
            value: '001',
            field: 'access_key',
            mutiple: false,
            required: true,
          },
          {
            label: '业务类型',
            type: 'select',
            value: '1',
            field: 'biz_type',
            mutiple: false,
            required: true,
             props: {
              options: [
                {
                  title: '销售类',
                  value: '1'
                },
                {
                  title: '调拨类',
                  value: '2'
                }, {
                  title: '其他',
                  value: '3'
                }
              ]
            }
          },
          {
            label: '客户订单号',
            readonly: true,
            type: 'text',
            value: '',
            field: 'order_id',
            mutiple: false,
            button: true
          }
        ],
        result: '', // 下单结果
        response: '', // 报文
        barcode: '' // 二维码参数
      };
    },
    methods: {
      goLogin() {
        this.$router.push({ path: '/login' });
      },
      getSelectData() {
        network.post('/p/c/wuliu/order/query_param', {}).then((res) => {
          if (res.data.code === 0) {
            this.formList = this.formList.map((item) => {
              if (item.type === 'date') {
                item.value = this.dateTranslate(new Date());
              }
              if (item.mutiple && item.type === 'select') {
                item.children = item.children.map((inner, index) => {
                  if (inner.label === '收件省份' || inner.label === '发件省份') {
                    inner.props.options = res.data.data.area;
                    if (inner.label === '发件省份') {
                      inner.value = '江苏省';
                      res.data.data.area.forEach((ele) => {
                        if (ele.value === 320000) {
                          item.children[index + 1].props.options = ele.childs;
                          item.children[index + 1].value = '南京市';
                          ele.childs.forEach((as) => {
                            if (as.value === 320100) {
                              item.children[index + 2].props.options = as.countyChilds;
                              item.children[index + 2].value = '雨花台区';
                            }
                          });
                        }
                      });
                    }
                    if (inner.label === '收件省份') {
                      inner.value = '上海';
                      res.data.data.area.forEach((ele) => {
                        if (ele.value === 310000) {
                          item.children[index + 1].props.options = ele.childs;
                          item.children[index + 1].value = '上海市';
                          ele.childs.forEach((as) => {
                            if (as.value === 310100) {
                              item.children[index + 2].props.options = as.countyChilds;
                              item.children[index + 2].value = '虹口区';
                            }
                          });
                        }
                      });
                    }
                  }
                  return inner;
                });
              }
              if (!item.mutiple && item.type === 'select') {
                if (item.field === 'store_name') {
                  item.props.options = res.data.data.storeInfoList; 
                }
                if (item.field === 'brand_name') {
                  item.props.options = res.data.data.brandInfoList;  
                }
                if (item.field === 'region_name') {
                  item.props.options = res.data.data.regionInfoList; 
                }
              } 
              return item;
            });
          }
        });
      }, // 获取select下拉数据
      add0(m) {
        return m < 10 ? `0${m}` : m;
      },
      timestamp() {
        const time = new Date();
        const y = time.getFullYear();
        const m = time.getMonth() + 1;
        const d = time.getDate();
        const h = time.getHours();
        const mm = time.getMinutes();
        const s = time.getSeconds();

        return (
          y
          + this.add0(m)
          + this.add0(d)
          + this.add0(h)
          + this.add0(mm)
          + this.add0(s)
        );
      },
      dateTranslate(date) {
        const nowdate = new Date(date).toLocaleDateString().replace(/\//g, '-');
        return nowdate;
      },
      dateChange(val, index) {
        this.formList[index].value = val;
        this.formList = this.formList.concat([]);
      },
      createBillNo(index) {
        this.formList[index].value = this.timestamp();
      }, // 生成单号
      onChange(e, index, childIndex) {
        // 城市的options
        const cityIndex = childIndex + 1;
        if (
          this.formList[index].children[childIndex].label.indexOf('省份') !== -1
        ) {
          this.formList[index].children[childIndex].props.options.forEach(
            (item) => {
              if (item.title === e) {
                this.formList[index].children[cityIndex].props.options = item.childs;
              }
            }
          );
        }
        if (
          this.formList[index].children[childIndex].label.indexOf('城市') !== -1
        ) {
          this.formList[index].children[childIndex].props.options.forEach(
            (item) => {
              if (item.title === e) {
                this.formList[index].children[cityIndex].props.options = item.countyChilds;
              }
            }
          );
        }
        this.formList = this.formList.concat([]);
      }, // select下拉事件
      getMD5Str(strArr) {
        const s1 = Array.prototype.sort.call(Object.keys(strArr), (a, b) => {
          for (let i = 0; i < a.length; i++) {
            if (a.charCodeAt(i) == b.charCodeAt(i)) continue;
            return a.charCodeAt(i) - b.charCodeAt(i);
          }
        });
        return s1;
      }, // 将要加密的key进行ascII码从大到小排列
      // 提交表单数据
      getSubmit() {
        const data = {};
        for (const key in this.formList) {
          if (this.formList[key].mutiple === false && this.formList[key].required && !this.formList[key].value) {
            this.$Message.warning('必填项不能为空');
            return;
          }
          if (
            this.formList[key].required
            && this.formList[key].mutiple
            && !this.formList[key].children[0].value
            && !this.formList[key].children[1].value
            && !this.formList[key].children[2].value
          ) {
            this.$Message.warning('省市区不能为空');
            return;
          }
          if (this.formList[key].mutiple === false) {
            if (!data[this.formList[key].field]) {
              data[this.formList[key].field] = this.formList[key].value;
            }
          } else {
            for (const inner in this.formList[key].children) {
              if (!data[this.formList[key].children[inner].field]) {
                data[this.formList[key].children[inner].field] = this.formList[
                  key
                ].children[inner].value;
              }
            }
          }
        }
        data.pay_method = '1'
        data.erp_order_id = data.order_id
        const newKeys = this.getMD5Str(data);
        let strA = '';
         newKeys.forEach((inner) => {
          Object.keys(data).forEach((outer) => {
              if (outer === inner && outer !== 'access_key') {
                strA += `${inner}=${data[inner]}&`;
              }
          });
        });     
        // Object.keys(data).forEach((outer) => {
        //   newKeys.forEach((inner) => {
        //     if (outer === inner && outer !== 'access_key') {
        //       strA += `${inner}=${data[inner]}&`;
        //     }
        //   });
        // });
        // strA = strA +`key=${data.access_key}`
        strA = strA +`key=123456`
        // strA = `access_key=${data.access_key}&`+strA.substr(0,strA.length-1);
        console.log(strA)
        data.sign = md5(strA).toUpperCase();
        network.post('/wuliu-app/create_order', data).then((res) => {
          this.response = JSON.stringify(res.data);
          this.result = res.data.result_code;
          if (res.data.result_code === 'SUCCESS') {
            
            this.barcode = res.data.mail_no;
            console.log(this.barcode)
          }
        });
      }
    },
    created() {
      this.getSelectData();

    //  console.log(this.$router,"路由")
    }
  };
</script>
<style lang="less">
 @media print {
   
 }
</style>

<style lang="less">
.order-mock {
  width: 100%;
  height: 100%;
  overflow: auto;
  .form-area {
    background: #fff;
    padding: 34px 201px 60px;
  }
  .head-area {
    background: #fff;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
    letter-spacing: 3px;
    align-items: center;
    
    .title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(253, 100, 66, 1);
      line-height: 33px;
      letter-spacing: 5px;
    }
    .mean-item {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(153, 153, 153, 1);
      letter-spacing: 3px;
      &:last-child {
        margin-left: 23px;
      }
    }
  }
  .line {
    width: 100%;
    height: 4px;
    background: rgba(253, 100, 66, 1);
    margin-top: 7px;
  }
  .form-content {
    background: #fff;
    margin-top: 44px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .form-item {
      box-sizing: border-box;
      width: 50%;
      margin-bottom: 16px;
      .form-label {
        width: 100%;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 16px;
      }
      .form-column {
        display: flex;
        .form-column-tw0 {
          box-sizing: border-box;
          flex: 1;
          margin-right: 19px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .form-item-detail {
        margin-top: 8px;
        width: 100%;
        display: flex;
        .burgeon-input-wrapper {
          height: 32px;
          .burgeon-input {
            height: 32px;
          }
        }
        .burgeon-select {
          height: 32px;
          .burgeon-select-selection {
            height: 32px;
          }
        }
        .form-text {
          display: inline-block;
          width: 100%;
          height: 32px;
          line-height: 30px;
          border-radius: 4px;
          padding-left:8px;
          border: 1px solid rgba(197, 197, 197, 1);
        }
        .operate {
          display: inline-block;
          width: 80px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border: 1px solid rgba(197, 197, 197, 1);
          border-radius: 4px;
          margin-left: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .form-column-detail {
          flex: 1;
          margin-right: 19px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &:nth-child(2n + 1) {
        padding-right: 100px;
      }
      &:nth-child(2n + 2) {
        padding-left: 100px;
      }
    }
  }
  .title-login {
    color: rgba(246, 141, 101, 1);
    padding:4px 10px;
    border:2px solid rgba(246, 141, 101, 1);
    cursor: pointer;
  }
  .burgeon-date-picker {
    width: 100%;
  }
  .require-mark {
    color: rgba(246, 141, 101, 1);
    vertical-align: middle;
  }
  .readonly {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: rgba(246, 141, 101, 1);
    border: 2px solid rgba(255, 214, 205, 1);
    border-radius: 50%;
    margin-bottom: 2px;
  }
  .btn-area {
    margin-top: 50px;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    text-align: center;
    .burgeon-btn-error {
      width: 302px;
    }
  }
  .result-area {
    margin-top: 60px;
    background: #fff;
    padding: 34px 201px 65px;
    .result-item {
      margin-top: 29px;
      .result-label {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(52, 52, 52, 1);
        line-height: 16px;
      }
      .result-show {
        margin-top: 20px;
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        border-bottom: 1px solid rgba(216, 216, 216, 1);
        line-height: 30px;
        .barCode {
          width: 176px;
          height: 105px;
        }
      }
    }
  }
}
</style>
